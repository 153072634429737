import React, { useEffect } from 'react';
import { connect } from 'react-redux';

import Button from '@material-ui/core/Button';
import IntlMessages from 'utils/IntlMessages';

import SettingsActions from 'store/reducers/Settings';
import UserService from 'services/User';
import AdminDashBoardImage from 'assets/images/kotler/dashboard-background-simple.jpeg';
import { Grid, Typography } from '@material-ui/core';
import IconCard from 'components/custom_v2/IconCard';

const Dashboard = ( { toggleLoading } ) => {
  useEffect( () => {
    toggleLoading( true );
    UserService.getCurrentUser().then( () => {
      toggleLoading( false );
    } );
  }, [toggleLoading] );

  return (
    <div
      className="container-fluid  flex-grow-1 h-100 d-flex flex-column"
      style={{
        backgroundImage: `url(${AdminDashBoardImage})`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
      }}
    >
      <div className="container-fluid mt-2 d-flex justify-content-center justify-content-sm-end">
        <Button
          key="save"
          variant="contained"
          color="primary"
          className="hr-button"
          onClick={() => {
            window.location.assign( '/newProfessor/' );
          }}
        >
          <IntlMessages id="createProfessorBtn" />
        </Button>
      </div>
      <div className="container-fluid flex-grow-1 d-flex justify-content-center align-items-center  container-dashboard-options-admin">
        <Grid container className="className='d-flex justify-content-center ">
          <Grid item xs={12}>
            <Typography
              variant="h6"
              color="textSecondary"
              className="text-center icon-card-section-title my-3"
            >
              <IntlMessages id="adminDashboard" />
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Grid
              container
              style={{ gap: '10vw' }}
              className="className='d-flex justify-content-center "
            >
              <Grid>
                <IconCard
                  icon="users"
                  title="users"
                  bgColor="bg-primary"
                  link="/users"
                />
              </Grid>
              <Grid>
                <IconCard
                  icon="clipboard-list"
                  title="params"
                  bgColor="bg-primary"
                  link="/params"
                />
              </Grid>
              <Grid>
                <IconCard
                  icon="university"
                  title="university"
                  bgColor="bg-primary"
                  link="/universities"
                />
              </Grid>
              <Grid>
                <IconCard
                  icon="tasks"
                  title="changelog"
                  bgColor="bg-primary"
                  link="/changelogs"
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>
    </div>
  );
};

const mapDispatchToProps = { toggleLoading: SettingsActions.toggleLoading };

export default connect(
  null,
  mapDispatchToProps,
)( Dashboard );
