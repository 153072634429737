import { CircularProgress } from '@material-ui/core';
import CardBox from 'components/custom_v2/CardBox';
import { useCapaignImageAiQuery } from 'modules/Campaigns';
import { useActiveSessionId, useMyCurrentWorkgroupId } from 'modules/sessions';
import React from 'react';
import IntlMessages from 'utils/IntlMessages';

export const AiGenerateImage = ( {
  contentType,
  service,
  serviceFeature,
  serviceFeature2,
  product,
  productFeature,
  productFeature2,
  bundle,
  bundleFeature,
  bundleFeature2,
} ) => {
  const sessionId = useActiveSessionId();
  const workGroupId = useMyCurrentWorkgroupId();
  const { imageQuery } = useCapaignImageAiQuery( sessionId, workGroupId, {
    contentType,
    service,
    serviceFeature,
    serviceFeature2,
    product,
    productFeature,
    productFeature2,
    bundle,
    bundleFeature,
    bundleFeature2,
  } );

  return (
    <div className="d-flex ">
      <CardBox
        styleName="px-4 pr-md-0 py-0 pb-0 h-100 w-100"
        disableHeader
        header={<div />}
      >
        {imageQuery.isLoading ? (
          <div
            style={{ aspectRatio: '1/1' }}
            className="w-100  d-flex justify-content-center align-items-center"
          >
            <CircularProgress size={30} thickness={5} />
          </div>
        ) : (
          <img
            style={{
              aspectRatio: '1/1',
              borderRadius: '10px',
              boxShadow: '-1px 2px 4px rgba(0,0,0,.5)',
            }}
            className="w-100"
            alt={contentType || 'ai-image'}
            src={imageQuery.data}
          />
        )}
        {!imageQuery.isLoading && (
          <h6
            style={{ fontSize: 10, letterSpacing: '1px', opacity: 0.8 }}
            className="text-dark mt-2 ml-1 mb-0"
          >
            <IntlMessages id="generatedImageByIA" />
          </h6>
        )}
      </CardBox>
    </div>
  );
};
