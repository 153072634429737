import React from 'react';
import { Form } from 'react-final-form';
import { connect } from 'react-redux';
import { Button, Grid } from '@material-ui/core';
import moment from 'moment-timezone';
import _ from 'lodash';
import async from 'async';
import Util from 'utils/Util';
import HeaderImage from 'assets/images/kotler/sectionHeaders/round-admin.jpeg';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import TimerIcon from '@material-ui/icons/Timer';
import CheckBoxIcon from '@material-ui/icons/Check';
// import CheckBoxIcon from '@material-ui/icons/CheckBox';
import TimelineIcon from '@material-ui/icons/Timeline';
import NotInterestedIcon from '@material-ui/icons/NotInterested';
import NewReleasesIcon from '@material-ui/icons/NewReleases';
import SupervisorAccountIcon from '@material-ui/icons/SupervisorAccount';
import PeopleIcon from '@material-ui/icons/People';
import IntlMessages from 'utils/IntlMessages';
import SettingsActions from 'store/reducers/Settings';
import RoundService from 'services/Round';
import SessionService from 'services/Session';
// import EntityService from 'services/Entity';
import GlobalVariableService from 'services/GlobalVariable';
import VersionVariableService from 'services/VersionVariable';
import HeadConfig from 'components/custom/HeadConfig';
import KotlerSectionHeader from 'components/custom/KoTlerSectionHeader';
import CardBox from 'components/custom_v2/CardBox';
import CustomTable from 'components/custom_v2/Table/Custom';
import KotlerTableTitle from 'components/custom/KotlerTableTitle';
import { RadioButtons } from 'components/custom/FormElements';
import ConfirmationDialog from 'components/custom_v2/ConfirmationDialog';
import { isRoundClosing } from 'modules/Rounds';
import { SessionTime } from './components/SessionTime';
// import ContainerHeader from 'components/theme/ContainerHeader';

class Info extends React.Component {
  activeClosingTime = false;
  activeClosingTypeSection = false;
  activeManualCloseSection = false;
  state = {
    data: [],
    currentRound: {},
    nuRounds: 0,
    nuRounds2: 0,
    openSessionDialog: false,
    openMultiDialog: false,
    openMultiDialog2: false,
    openSingleDialog: false,
    openSimulationDialog: false,
    dropdownOpen: false,
    dataSession: null,
  };

  componentDidMount() {
    this.getData();
  }

  async getData() {
    const { toggleLoading, sessionId } = this.props;
    toggleLoading( true );
    async.waterfall(
      [
        ( cb ) => {
          VersionVariableService.getLastVersion().then( ( response ) => {
            if ( !response.ok ) return cb( response.errors );
            cb( null, response.data );
          } );
        },
        ( version, cb ) => {
          GlobalVariableService.getVariables( {
            'filters[version]': version.id,
            'filters[label]': 'CLOSE_MULTIPLE_ROUNDS',
            limit: -1,
          } ).then( ( response ) => {
            if ( !response.ok ) return cb( response.errors );
            cb( null, response.data );
          } );
        },
      ],
      ( error, results ) => {
        if ( !error ) {
          this.setState( { nuRounds: results.data[0].value } );
        }
      },
    );

    async.waterfall(
      [
        ( cb ) => {
          VersionVariableService.getLastVersion().then( ( response ) => {
            if ( !response.ok ) return cb( response.errors );
            cb( null, response.data );
          } );
        },
        ( version, cb ) => {
          GlobalVariableService.getVariables( {
            'filters[version]': version.id,
            'filters[label]': 'CLOSE_MULTIPLE_ROUNDS_2',
            limit: -1,
          } ).then( ( response ) => {
            if ( !response.ok ) return cb( response.errors );
            cb( null, response.data );
          } );
        },
      ],
      ( error, results ) => {
        if ( !error ) {
          this.setState( { nuRounds2: results.data[0].value } );
        }
      },
    );
    async.parallel(
      {
        session: ( cb ) => {
          SessionService.getSession( sessionId ).then( ( response ) => {
            if ( !response.ok ) return cb( response.errors );
            cb( null, response.data );
          } );
        },
        rounds: ( cb ) => {
          RoundService.getRoundsBySession( sessionId, { limit: -1 } ).then(
            ( response ) => {
              if ( !response.ok ) return cb( response.errors );
              cb( null, response.data );
            },
          );
        },
      },
      ( error, results ) => {
        if ( !error ) {
          const {
            marketMaturity,
            simulationSpeed,
            closingDateTestRound,
            withTestRound,
          } = results.session;
          const rounds = results.rounds.data || [];
          const data = rounds.filter( round => ( withTestRound && closingDateTestRound
            ? Number( round.endDate ) <= Number( closingDateTestRound )
            : true ) );
          this.setState( {
            marketMaturity,
            simulationSpeed,
            marketMaturityPrev: marketMaturity,
            simulationSpeedPrev: results.session.simulationSpeed,
            dataSession: results.session,
            data,
            currentRound: results.rounds.currentRound,
          } );
        }
        toggleLoading( false );
      },
    );
  }

  // eslint-disable-next-line no-unused-vars
  submitForm = ( formData, form ) => {
    const { toggleLoading, toggleAlert, sessionId } = this.props;
    const dataToSend = {};

    if ( formData.closingType === 'CLOSING_TIME' ) {
      toggleLoading( true );
      const adjustedHour = moment
        .tz( '2013-08-26 04:00:00', formData.timeZone )
        .utc()
        .format( 'HH:mm' );
      dataToSend.endTime = adjustedHour;
      /*   dataToSend.endTime = moment( Number( formData.closingTime ) ).format( 'HH:mm' ); */
      // dataToSend.manualClosing = false;
      RoundService.changeClosingTime( sessionId, dataToSend ).then( ( response ) => {
        toggleLoading( false );
        if ( !response.ok ) {
          toggleAlert( response.errors );
        } else {
          toggleAlert( 'dataSaved', 'info' );
          this.getData();
        }
      } );
    }

    if ( formData.closingType === 'MANUAL_CLOSING' ) {
      toggleLoading( true );
      dataToSend.manualClosing = true;
      RoundService.changeManualClosing( sessionId, dataToSend ).then( ( response ) => {
        toggleLoading( false );
        if ( !response.ok ) {
          toggleAlert( response.errors );
        } else {
          toggleAlert( 'dataSaved', 'info' );
          this.getData();
        }
      } );
    }
  };

  closeRound = () => {
    this.setState( previousState => ( { openSingleDialog: !previousState.openSingleDialog } ) );
    const { toggleLoading, toggleAlert, sessionId } = this.props;
    toggleLoading( true );
    RoundService.closeRound( sessionId ).then( ( response ) => {
      toggleLoading( false );
      if ( !response.ok ) {
        toggleAlert( response.errors );
      } else {
        toggleAlert( 'roundClosed', 'info' );
        this.getData();
      }
    } );
  };

  closeSession = () => {
    this.setState( previousState => ( { openSessionDialog: !previousState.openSessionDialog } ) );
    const { toggleLoading, toggleAlert, sessionId } = this.props;
    toggleLoading( true );
    SessionService.closeSession( sessionId ).then( ( response ) => {
      toggleLoading( false );
      if ( !response.ok ) {
        toggleAlert( response.errors );
      } else {
        toggleAlert( 'sessionClosed', 'info' );
        this.getData();
      }
    } );
  };

  updateSimulation = () => {
    // eslint-disable-next-line max-len
    this.setState( previousState => ( { openSimulationDialog: !previousState.openSimulationDialog } ) );
    const { marketMaturity, simulationSpeed } = this.state;
    const { toggleLoading, toggleAlert, sessionId } = this.props;
    toggleLoading( true );
    const dataToSend = {};
    dataToSend.simulationSpeed = simulationSpeed;
    dataToSend.marketMaturity = marketMaturity;
    SessionService.updateSimulation( sessionId, dataToSend ).then( ( response ) => {
      toggleLoading( false );
      if ( !response.ok ) {
        toggleAlert( response.errors );
      } else {
        toggleAlert( 'dataSaved', 'info' );
        this.getData();
      }
    } );
  };

  closeMultipleRound = () => {
    this.setState( previousState => ( { openMultiDialog: !previousState.openMultiDialog } ) );
    const { toggleLoading, toggleAlert, sessionId } = this.props;
    toggleLoading( true );
    RoundService.closeMultipleRound1( sessionId ).then( ( response ) => {
      toggleLoading( false );
      if ( !response.ok ) {
        toggleAlert( response.errors );
      } else {
        toggleAlert( 'roundsClosed', 'info' );
        this.getData();
      }
    } );
  };

  closeMultipleRound2 = () => {
    this.setState( previousState => ( { openMultiDialog2: !previousState.openMultiDialog2 } ) );
    const { toggleLoading, toggleAlert, sessionId } = this.props;
    toggleLoading( true );
    RoundService.closeMultipleRound2( sessionId ).then( ( response ) => {
      toggleLoading( false );
      if ( !response.ok ) {
        toggleAlert( response.errors );
      } else {
        toggleAlert( 'roundsClosed', 'info' );
        this.getData();
      }
    } );
  };

  handleClickOpenSessionDialog = () => {
    this.setState( previousState => ( { openSessionDialog: !previousState.openSessionDialog } ) );
  };

  handleCloseSessionDialog = () => {
    this.setState( previousState => ( { openSessionDialog: !previousState.openSessionDialog } ) );
  };

  handleSimulationDialog = () => {
    // eslint-disable-next-line max-len
    this.setState( previousState => ( { openSimulationDialog: !previousState.openSimulationDialog } ) );
  };

  handleClickOpenMultiDialog = () => {
    this.setState( previousState => ( { openMultiDialog: !previousState.openMultiDialog } ) );
  };
  handleClickOpenMultiDialog2 = () => {
    this.setState( previousState => ( { openMultiDialog2: !previousState.openMultiDialog2 } ) );
  };
  handleCloseMultiDialog = () => {
    this.setState( previousState => ( { openMultiDialog: !previousState.openMultiDialog } ) );
  };
  handleCloseMultiDialog2 = () => {
    this.setState( previousState => ( { openMultiDialog2: !previousState.openMultiDialog2 } ) );
  };
  handleClickOpenSingleDialog = () => {
    this.setState( previousState => ( { openSingleDialog: !previousState.openSingleDialog } ) );
  };

  handleCloseSingleDialog = () => {
    this.setState( previousState => ( { openSingleDialog: !previousState.openSingleDialog } ) );
  };

  toggleDrop = () => {
    this.setState( previousState => ( { dropdownOpen: !previousState.dropdownOpen } ) );
  };

  render() {
    const {
      data,
      nuRounds,
      nuRounds2,
      openSessionDialog,
      openSimulationDialog,
      openSingleDialog,
      openMultiDialog,
      openMultiDialog2,
      currentRound,
      marketMaturity,
      marketMaturityPrev,
      simulationSpeed,
      simulationSpeedPrev,
      dataSession,
    } = this.state;
    this.activeClosingTime = !currentRound.manualClosing;
    return (
      <Form
        onSubmit={this.submitForm}
        initialValues={{
          rounds: 1,
          typeDuration: 'days',
          marketMaturity,
          simulationSpeed,
          closingType: currentRound.manualClosing
            ? 'MANUAL_CLOSING'
            : 'CLOSING_TIME',
          // eslint-disable-next-line max-len
          closingTime:
            currentRound.closingTime && !currentRound.manualClosing
              ? moment( new Date( `2020-01-01 ${currentRound.closingTime}` ) )
              : null,
        }}
        /*   validate={( values ) => {
          const errors = {};
          if ( ( values.closingType === 'CLOSING_TIME' ) && ( !values.closingTime ) ) {
            errors.closingTime = required( values.closingTime );
          }

          return errors;
        }} */
        render={( { handleSubmit, form } ) => (
          <form onSubmit={handleSubmit}>
            <HeadConfig
              breadcrumbsLinks={[
                {
                  url: '/',
                  name: 'HomeLink',
                },
                { name: 'DecisionDashboard-roundmngmnt' },
              ]}
            />
            <KotlerSectionHeader title="roundmngmnt" image={HeaderImage} />
            <Grid container justify="space-between" style={{ rowGap: '20px' }}>
              <Grid item xs={12} sm={6} md={5} lg={5}>
                <CardBox styleName="px-2 h-100">
                  <Grid container style={{ rowGap: '8px' }}>
                    <Grid item xs={12} className="d-flex flex-column">
                      <IntlMessages id="labelSectionRoundClose" />
                      <Button
                        variant="outlined"
                        color="primary"
                        className="hr-button"
                        onClick={this.handleClickOpenSingleDialog}
                      >
                        <IntlMessages id="yesCloseCurrentRound" />
                      </Button>
                    </Grid>
                    <Grid item xs={12} className="d-flex flex-column">
                      <IntlMessages id="labelSectionRoundCloseShortSession" />
                      <div className="d-flex align-content-center w-100">
                        <Button
                          variant="outlined"
                          color="primary"
                          className="hr-button flex-grow-1"
                          onClick={this.handleClickOpenMultiDialog}
                        >
                          <IntlMessages
                            id="yesCloseNRound"
                            values={{ count: nuRounds }}
                          />
                        </Button>

                        <Button
                          variant="outlined"
                          color="primary"
                          className="hr-button flex-grow-1"
                          onClick={this.handleClickOpenMultiDialog2}
                        >
                          <IntlMessages
                            id="yesCloseNRound"
                            values={{ count: nuRounds2 }}
                          />
                        </Button>
                      </div>
                    </Grid>
                    <Grid item xs={12} className="d-flex flex-column">
                      <IntlMessages id="session" />
                      <Button
                        variant="contained"
                        color="secondary"
                        className="hr-button"
                        onClick={this.handleClickOpenSessionDialog}
                      >
                        <IntlMessages id="closeSession" />
                      </Button>
                    </Grid>
                  </Grid>
                </CardBox>
              </Grid>
              <Grid item xs={12} sm={3} md={3} lg={3}>
                <CardBox styleName="px-2 h-100">
                  <b>
                    <IntlMessages id="simulationSpeed" />
                  </b>
                  <RadioButtons
                    field="simulationSpeed"
                    valueKey="name"
                    options={[
                      {
                        id: 'low',
                        name: 'low',
                      },
                      {
                        id: 'normal',
                        name: 'normal',
                      },
                      {
                        id: 'high',
                        name: 'high',
                      },
                    ]}
                    onChange={( value ) => {
                      this.setState( {
                        simulationSpeed: value,
                        simulationSpeedPrev: simulationSpeed,
                        openSimulationDialog: true,
                      } );
                    }}
                    displayBlock
                  />
                </CardBox>
              </Grid>
              <Grid item xs={12} sm={3} md={4} lg={4}>
                <CardBox styleName="px-2 h-100">
                  <b>
                    <IntlMessages id="labelSectionRoundClosingType" />
                  </b>
                  <RadioButtons
                    field="closingType"
                    valueKey="name"
                    options={[
                      {
                        id: 'CLOSING_TIME',
                        name: 'CLOSING_TIME',
                      },
                      {
                        id: 'MANUAL_CLOSING',
                        name: 'MANUAL_CLOSING',
                      },
                    ]}
                    onChange={( value ) => {
                      form.change( 'closeRound', null );
                      if ( value === 'MANUAL_CLOSING' ) {
                        form.change( 'closingTime', null );
                        this.activeClosingTime = false;
                      }
                      if ( value === 'CLOSING_TIME' ) {
                        this.activeClosingTime = true;
                      }
                    }}
                    displayBlock
                  />
                  <Button
                    variant="outlined"
                    color="primary"
                    className="hr-button mt-2"
                    type="submit"
                  >
                    <IntlMessages id="alterRounds" />
                  </Button>
                </CardBox>
              </Grid>

              <Grid item xs={12}>
                <CardBox styleName="px-2 h-100">
                  <KotlerTableTitle title="sessionInformation" icon="info" />
                  <Grid container className="mt-2">
                    <Grid item xs={12} sm={6} md={3}>
                      <b>
                        <IntlMessages id="totalStudents" />
                      </b>
                      <div className="d-flex">
                        <PeopleIcon className="mr-2" />
                        {dataSession && dataSession.licensesTotal}
                      </div>
                      <b>
                        <IntlMessages id="timeZone" />
                      </b>
                      <div className="d-flex">
                        <TimerIcon className="mr-2" />

                        {dataSession && dataSession.timeZone}
                      </div>
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                      <b>
                        <IntlMessages id="Professor" />
                      </b>
                      <div className="d-flex">
                        <SupervisorAccountIcon className="mr-2" />
                        {dataSession
                          && _.get(
                            dataSession.currentSupervisor,
                            'name',
                            '--',
                          )}
                        {' '}
                        {dataSession
                          && _.get(
                            dataSession.currentSupervisor,
                            'lastName',
                            '--',
                          )}
                      </div>
                      <b>
                        <IntlMessages id="withCertificate" />
:
                        <div className="d-flex">
                          {dataSession && dataSession.withCertificate ? (
                            /* eslint-disable-next-line max-len */
                            <CheckBoxIcon />
                          ) : (
                            <NotInterestedIcon style={{ fontSize: 15 }} />
                          )}
                        </div>
                      </b>
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                      <b>
                        <IntlMessages id="distribution" />
                      </b>
                      <div className="d-flex">
                        <NewReleasesIcon className="mr-2" />
                        {dataSession && dataSession.distributionType}
                      </div>
                      <b>
                        <IntlMessages id="marketingBudget" />
                      </b>
                      <div className="d-flex">
                        <AttachMoneyIcon className="mr-2" />
                        {dataSession
                          && Util.formatCurrencyNoDecimals(
                            dataSession.marketingBudget,
                          )}
                      </div>
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                      <b>
                        <IntlMessages id="marketMaturity" />
                      </b>
                      <div className="d-flex">
                        <TimelineIcon className="mr-2" />

                        {dataSession && dataSession.marketMaturity}
                      </div>
                      <b>
                        <IntlMessages id="withStragegy" />
:
                        {dataSession && dataSession.withStrategies ? (
                          /* eslint-disable-next-line max-len */
                          <CheckBoxIcon />
                        ) : (
                          <NotInterestedIcon style={{ fontSize: 15 }} />
                        )}
                      </b>
                    </Grid>
                  </Grid>
                </CardBox>
              </Grid>
              <Grid item xs={12}>
                <CardBox styleName="px-2 h-100" heading={null}>
                  <KotlerTableTitle title="currentRounds" icon="rotateCcw" />
                  {data.length && dataSession ? (
                    <CustomTable
                      data={data}
                      columns={[
                        {
                          Header: <IntlMessages id="round" />,
                          accessor: 'name',
                          Cell: ( { value, original } ) => `${value} ${original.startDateInMonth || ''}`,
                        },
                        {
                          Header: <IntlMessages id="start" />,
                          accessor: 'startDate',
                          Cell: props => (
                            <SessionTime
                              sessionTimeZone={dataSession.timeZone}
                              value={props.value}
                            />
                          ),
                        },
                        {
                          Header: <IntlMessages id="end" />,
                          accessor: 'endDate',
                          Cell: ( { value, original } ) => ( original.manualClosing === true ? (
                            '---'
                          ) : (
                            <SessionTime
                              sessionTimeZone={dataSession.timeZone}
                              value={value}
                            />
                          ) ),
                        },
                        {
                          Header: <IntlMessages id="status" />,
                          accessor: 'status',
                          Cell: ( { value, original } ) => {
                            if ( isRoundClosing( original ) ) return 'CLOSING';
                            return value;
                          },
                        },
                        /*  {
                          Header: <IntlMessages id="roundReport" />,
                          accessor: 'roundReport',
                          Cell: ( { value } ) => ( value
                            ? (
                              <a
                                rel="noopener noreferrer"
                                className="text-blue"
                                target="_blank"
                                href={value}
                              >
                                <IntlMessages id="report" />
                              </a>
                            ) : '' ),
                        }, */
                      ]}
                    />
                  ) : (
                    <div className="p-5 text-center text-muted">
                      <h3 className="m-0">No rounds</h3>
                    </div>
                  )}
                </CardBox>
              </Grid>
            </Grid>

            <ConfirmationDialog
              open={openSingleDialog}
              title="confirmCloseRound"
              disableMarginBotton
              type="warning"
              confirmBtnText="ok"
              closeBtnText="cancelBtn"
              onSubmit={this.closeRound}
              onClose={this.handleCloseSingleDialog}
            >
              <IntlMessages
                id="closing_runds_alert_message"
                values={{ min: 10, max: 15 }}
              />
            </ConfirmationDialog>

            <ConfirmationDialog
              open={openMultiDialog}
              title="confirmCloseRound"
              disableMarginBotton
              type="warning"
              confirmBtnText="ok"
              closeBtnText="cancelBtn"
              onSubmit={this.closeMultipleRound}
              onClose={this.handleCloseMultiDialog}
            >
              <IntlMessages
                id="closing_6_runds_alert_message"
                values={{ min: 30, max: 40 }}
              />
            </ConfirmationDialog>

            <ConfirmationDialog
              open={openMultiDialog2}
              title="confirmCloseRound"
              disableMarginBotton
              type="warning"
              confirmBtnText="ok"
              closeBtnText="cancelBtn"
              onSubmit={this.closeMultipleRound2}
              onClose={this.handleCloseMultiDialog2}
            >
              <IntlMessages
                id="closing_6_runds_alert_message"
                values={{ min: 50, max: 60 }}
              />
            </ConfirmationDialog>
            <ConfirmationDialog
              open={openSessionDialog}
              title="confirmCloseSession"
              disableMarginBotton
              showIcon
              type="warning"
              confirmBtnText="ok"
              closeBtnText="cancelBtn"
              onSubmit={this.closeSession}
              onClose={this.handleCloseSessionDialog}
            />
            <ConfirmationDialog
              open={openSimulationDialog}
              title="confirmUpdateSimulation"
              disableMarginBotton
              type="save"
              confirmBtnText="ok"
              closeBtnText="cancelBtn"
              onSubmit={this.updateSimulation}
              onClose={() => {
                this.setState( {
                  marketMaturity: marketMaturityPrev,
                  simulationSpeed: simulationSpeedPrev,
                } );
                this.handleSimulationDialog();
              }}
            />
          </form>
        )}
      />
    );
  }
}

const mapStateToProps = ( { user } ) => ( { user } );

const mapDispatchToProps = {
  toggleAlert: SettingsActions.toggleAlert,
  toggleLoading: SettingsActions.toggleLoading,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)( Info );
