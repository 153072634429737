import React from 'react';
import {
  Dialog,
  Slide,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  DialogContent,
} from '@material-ui/core';
import CardBox from 'components/custom_v2/CardBox';
import Util from 'utils/Util';
import IntlMessages from 'utils/IntlMessages';
import KotlerCardHeader from 'components/custom/KotlerCardHeader';
import KotlerHeaderFullWidthModal from 'components/custom/KotlerHeaderFullWidthModal';

const CompModal = ( { title, open, onClose, data, mkmode } ) => (
  <Dialog
    fullScreen
    open={open}
    onClose={onClose}
    TransitionComponent={Slide}
    TransitionProps={{ direction: 'up' }}
  >
    <KotlerHeaderFullWidthModal {...{ title, onClose }} />
    <DialogContent className="px-2">
      {!!data && data.length > 0 && (
        <CardBox
          styleName="px-2 "
          header={
            <KotlerCardHeader title="ProfitLossStatement" minHeight="0px" />
          }
        >
          <div className="jr-link" id="PLossCompdiv" />
          <Table className="kotler-table-info-details  kotler-table-info-sm kotler-table-profit-and-lost">
            <TableHead />
            <TableBody>
              {/* START RENUEVES */}
              <TableRow className="subtitle">
                <TableCell colSpan={11}>
                  <IntlMessages id="Revenues" />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  <IntlMessages id="RevenuesFromGoodSold" />
                </TableCell>
                <TableCell align="right">
                  {Util.formatNumber( data[0].revenuesFromGoodsSold )}
                </TableCell>
                <TableCell />
                <TableCell align="right">
                  {Util.formatNumber( data[1].revenuesFromGoodsSold )}
                </TableCell>
                <TableCell />
                <TableCell align="right">
                  {Util.formatNumber( data[2].revenuesFromGoodsSold )}
                </TableCell>
                <TableCell />
                <TableCell align="right">
                  {Util.formatNumber( data[3].revenuesFromGoodsSold )}
                </TableCell>
                <TableCell />
                <TableCell align="right">
                  {Util.formatNumber( data[4].revenuesFromGoodsSold )}
                </TableCell>
                <TableCell />
              </TableRow>
              {mkmode.information.company.profitAndLoss.revenuesFromServ && (
                <TableRow>
                  <TableCell>
                    <IntlMessages id="RevenuesFromServ" />
                  </TableCell>
                  <TableCell align="right">
                    {Util.formatNumber( data[0].revenuesFromServicesSold )}
                  </TableCell>
                  <TableCell />
                  <TableCell align="right">
                    {Util.formatNumber( data[1].revenuesFromServicesSold )}
                  </TableCell>
                  <TableCell />
                  <TableCell align="right">
                    {Util.formatNumber( data[2].revenuesFromServicesSold )}
                  </TableCell>
                  <TableCell />
                  <TableCell align="right">
                    {Util.formatNumber( data[3].revenuesFromServicesSold )}
                  </TableCell>
                  <TableCell />
                  <TableCell align="right">
                    {Util.formatNumber( data[4].revenuesFromServicesSold )}
                  </TableCell>
                  <TableCell />
                </TableRow>
              )}
              {mkmode.information.company.profitAndLoss
                .revenuesFromBundlesSales && (
                <TableRow>
                  <TableCell>
                    <IntlMessages id="RevenuesFromBundlesSales" />
                  </TableCell>
                  <TableCell align="right">
                    {Util.formatNumber( data[0].revenuesFromBundlesSold )}
                  </TableCell>
                  <TableCell />
                  <TableCell align="right">
                    {Util.formatNumber( data[1].revenuesFromBundlesSold )}
                  </TableCell>
                  <TableCell />
                  <TableCell align="right">
                    {Util.formatNumber( data[2].revenuesFromBundlesSold )}
                  </TableCell>
                  <TableCell />
                  <TableCell align="right">
                    {Util.formatNumber( data[3].revenuesFromBundlesSold )}
                  </TableCell>
                  <TableCell />
                  <TableCell align="right">
                    {Util.formatNumber( data[4].revenuesFromBundlesSold )}
                  </TableCell>
                  <TableCell />
                </TableRow>
              )}
              <TableRow className="bg-gray-3">
                <TableCell>
                  <IntlMessages id="TotalRevenuesFromSales" />
                </TableCell>
                <TableCell align="right">
                  {Util.formatNumber( data[0].totalRevenuesFromSales )}
                </TableCell>
                <TableCell />
                <TableCell align="right">
                  {Util.formatNumber( data[1].totalRevenuesFromSales )}
                </TableCell>
                <TableCell />
                <TableCell align="right">
                  {Util.formatNumber( data[2].totalRevenuesFromSales )}
                </TableCell>
                <TableCell />
                <TableCell align="right">
                  {Util.formatNumber( data[3].totalRevenuesFromSales )}
                </TableCell>
                <TableCell />
                <TableCell align="right">
                  {Util.formatNumber( data[4].totalRevenuesFromSales )}
                </TableCell>
                <TableCell />
              </TableRow>
              {/* END RENUEVES */}
              {/* START DIRECT EXPENSES */}
              <TableRow className="subtitle">
                <TableCell colSpan={11}>
                  <IntlMessages id="DirectExpenses" />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  <IntlMessages id="CostOfGoodsSold" />
                </TableCell>
                <TableCell align="right">
                  {Util.formatNumber( data[0].costOfGoodsSold )}
                </TableCell>
                <TableCell />
                <TableCell align="right">
                  {Util.formatNumber( data[1].costOfGoodsSold )}
                </TableCell>
                <TableCell />
                <TableCell align="right">
                  {Util.formatNumber( data[2].costOfGoodsSold )}
                </TableCell>
                <TableCell />
                <TableCell align="right">
                  {Util.formatNumber( data[3].costOfGoodsSold )}
                </TableCell>
                <TableCell />
                <TableCell align="right">
                  {Util.formatNumber( data[4].costOfGoodsSold )}
                </TableCell>
                <TableCell />
              </TableRow>
              {mkmode.information.company.profitAndLoss.costOfServicesSold && (
                <TableRow>
                  <TableCell>
                    <IntlMessages id="CostOfServicesSold" />
                  </TableCell>
                  <TableCell align="right">
                    {Util.formatNumber( data[0].costOfServicesSold )}
                  </TableCell>
                  <TableCell />
                  <TableCell align="right">
                    {Util.formatNumber( data[1].costOfServicesSold )}
                  </TableCell>
                  <TableCell />
                  <TableCell align="right">
                    {Util.formatNumber( data[2].costOfServicesSold )}
                  </TableCell>
                  <TableCell />
                  <TableCell align="right">
                    {Util.formatNumber( data[3].costOfServicesSold )}
                  </TableCell>
                  <TableCell />
                  <TableCell align="right">
                    {Util.formatNumber( data[4].costOfServicesSold )}
                  </TableCell>
                  <TableCell />
                </TableRow>
              )}
              {mkmode.information.company.profitAndLoss.costOfBundlesSold && (
                <TableRow>
                  <TableCell>
                    <IntlMessages id="CostOfBundlesSold" />
                  </TableCell>
                  <TableCell align="right">
                    {Util.formatNumber( data[0].costOfBundlesSold )}
                  </TableCell>
                  <TableCell />
                  <TableCell align="right">
                    {Util.formatNumber( data[1].costOfBundlesSold )}
                  </TableCell>
                  <TableCell />
                  <TableCell align="right">
                    {Util.formatNumber( data[2].costOfBundlesSold )}
                  </TableCell>
                  <TableCell />
                  <TableCell align="right">
                    {Util.formatNumber( data[3].costOfBundlesSold )}
                  </TableCell>
                  <TableCell />
                  <TableCell align="right">
                    {Util.formatNumber( data[4].costOfBundlesSold )}
                  </TableCell>
                  <TableCell />
                </TableRow>
              )}
              <TableRow className="bg-gray-3">
                <TableCell>
                  <IntlMessages id="TotalCostFromSales" />
                </TableCell>
                <TableCell align="right">
                  {Util.formatNumber( data[0].totalCostsFormSales )}
                </TableCell>
                <TableCell />
                <TableCell align="right">
                  {Util.formatNumber( data[1].totalCostsFormSales )}
                </TableCell>
                <TableCell />
                <TableCell align="right">
                  {Util.formatNumber( data[2].totalCostsFormSales )}
                </TableCell>
                <TableCell />
                <TableCell align="right">
                  {Util.formatNumber( data[3].totalCostsFormSales )}
                </TableCell>
                <TableCell />
                <TableCell align="right">
                  {Util.formatNumber( data[4].totalCostsFormSales )}
                </TableCell>
                <TableCell />
              </TableRow>
              <TableRow className="bg-gray-3">
                <TableCell>
                  <IntlMessages id="Grossprofit" />
                </TableCell>
                <TableCell align="right">
                  {Util.formatNumber( data[0].grossProfit )}
                </TableCell>
                <TableCell align="right">
                  {Util.formatNumberFixedDec(
                    ( data[0].grossProfit / data[0].totalRevenuesFromSales ) * 100,
                  )}
                  %
                </TableCell>

                <TableCell align="right">
                  {Util.formatNumber( data[1].grossProfit )}
                </TableCell>
                <TableCell align="right">
                  {Util.formatNumberFixedDec(
                    ( data[1].grossProfit / data[1].totalRevenuesFromSales ) * 100,
                  )}
                  %
                </TableCell>

                <TableCell align="right">
                  {Util.formatNumber( data[2].grossProfit )}
                </TableCell>
                <TableCell align="right">
                  {Util.formatNumberFixedDec(
                    ( data[2].grossProfit / data[2].totalRevenuesFromSales ) * 100,
                  )}
                  %
                </TableCell>
                <TableCell align="right">
                  {Util.formatNumber( data[3].grossProfit )}
                </TableCell>
                <TableCell align="right">
                  {Util.formatNumberFixedDec(
                    ( data[3].grossProfit / data[3].totalRevenuesFromSales ) * 100,
                  )}
                  %
                </TableCell>
                <TableCell align="right">
                  {Util.formatNumber( data[4].grossProfit )}
                </TableCell>
                <TableCell align="right">
                  {Util.formatNumberFixedDec(
                    ( data[4].grossProfit / data[4].totalRevenuesFromSales ) * 100,
                  )}
                  %
                </TableCell>
              </TableRow>
              {/* END DIRECT EXPENSES */}
              {/* START INDIRECT EXPENSES */}

              <TableRow className="subtitle">
                <TableCell colSpan={11}>
                  <IntlMessages id="IndirectExpenses" />
                </TableCell>
              </TableRow>

              <TableRow>
                <TableCell>
                  <IntlMessages id="GeneralAdministrative" />
                </TableCell>

                <TableCell align="right">
                  {Util.formatNumber( data[0].generalAdministrative )}
                </TableCell>
                <TableCell />
                <TableCell align="right">
                  {Util.formatNumber( data[1].generalAdministrative )}
                </TableCell>
                <TableCell />
                <TableCell align="right">
                  {Util.formatNumber( data[2].generalAdministrative )}
                </TableCell>
                <TableCell />
                <TableCell align="right">
                  {Util.formatNumber( data[3].generalAdministrative )}
                </TableCell>
                <TableCell />
                <TableCell align="right">
                  {Util.formatNumber( data[4].generalAdministrative )}
                </TableCell>
                <TableCell />
              </TableRow>

              <TableRow>
                <TableCell>
                  <IntlMessages id="MarketingCosts" />
                </TableCell>
                <TableCell align="right">
                  {Util.formatNumber( data[0].marketingCost )}
                </TableCell>
                <TableCell />
                <TableCell align="right">
                  {Util.formatNumber( data[1].marketingCost )}
                </TableCell>
                <TableCell />
                <TableCell align="right">
                  {Util.formatNumber( data[2].marketingCost )}
                </TableCell>
                <TableCell />
                <TableCell align="right">
                  {Util.formatNumber( data[3].marketingCost )}
                </TableCell>
                <TableCell />
                <TableCell align="right">
                  {Util.formatNumber( data[4].marketingCost )}
                </TableCell>
                <TableCell />
              </TableRow>

              <TableRow>
                <TableCell>
                  <IntlMessages id="DirectSalesCosts" />
                </TableCell>
                <TableCell align="right">
                  {Util.formatNumber( data[0].directSalesCost )}
                </TableCell>
                <TableCell />
                <TableCell align="right">
                  {Util.formatNumber( data[1].directSalesCost )}
                </TableCell>
                <TableCell />
                <TableCell align="right">
                  {Util.formatNumber( data[2].directSalesCost )}
                </TableCell>
                <TableCell />
                <TableCell align="right">
                  {Util.formatNumber( data[3].directSalesCost )}
                </TableCell>
                <TableCell />
                <TableCell align="right">
                  {Util.formatNumber( data[4].directSalesCost )}
                </TableCell>
                <TableCell />
              </TableRow>

              <TableRow>
                <TableCell>
                  <IntlMessages id="StockHandlingCosts" />
                </TableCell>
                <TableCell align="right">
                  {Util.formatNumber( data[0].stockHandlingCosts )}
                </TableCell>
                <TableCell />
                <TableCell align="right">
                  {Util.formatNumber( data[1].stockHandlingCosts )}
                </TableCell>
                <TableCell />
                <TableCell align="right">
                  {Util.formatNumber( data[2].stockHandlingCosts )}
                </TableCell>
                <TableCell />
                <TableCell align="right">
                  {Util.formatNumber( data[3].stockHandlingCosts )}
                </TableCell>
                <TableCell />
                <TableCell align="right">
                  {Util.formatNumber( data[4].stockHandlingCosts )}
                </TableCell>
                <TableCell />
              </TableRow>
              <TableRow>
                <TableCell>
                  <IntlMessages id="RandD" />
                </TableCell>
                <TableCell align="right">
                  {Util.formatNumber( data[0].rd )}
                </TableCell>
                <TableCell />
                <TableCell align="right">
                  {Util.formatNumber( data[1].rd )}
                </TableCell>
                <TableCell />
                <TableCell align="right">
                  {Util.formatNumber( data[2].rd )}
                </TableCell>
                <TableCell />
                <TableCell align="right">
                  {Util.formatNumber( data[3].rd )}
                </TableCell>
                <TableCell />
                <TableCell align="right">
                  {Util.formatNumber( data[4].rd )}
                </TableCell>
                <TableCell />
              </TableRow>
              <TableRow>
                <TableCell>
                  <IntlMessages id="InterestsFromLoans" />
                </TableCell>
                <TableCell align="right">
                  {Util.formatNumber( data[0].interestsFromLoans )}
                </TableCell>
                <TableCell />
                <TableCell align="right">
                  {Util.formatNumber( data[1].interestsFromLoans )}
                </TableCell>
                <TableCell />
                <TableCell align="right">
                  {Util.formatNumber( data[2].interestsFromLoans )}
                </TableCell>
                <TableCell />
                <TableCell align="right">
                  {Util.formatNumber( data[3].interestsFromLoans )}
                </TableCell>
                <TableCell />
                <TableCell align="right">
                  {Util.formatNumber( data[4].interestsFromLoans )}
                </TableCell>
                <TableCell />
              </TableRow>
              <TableRow className="bg-gray-3">
                <TableCell>
                  <IntlMessages id="TotalIndirectsExpenses" />
                </TableCell>
                <TableCell align="right">
                  {Util.formatNumber( data[0].totalIndirectExpenses )}
                </TableCell>
                <TableCell />
                <TableCell align="right">
                  {Util.formatNumber( data[1].totalIndirectExpenses )}
                </TableCell>
                <TableCell />
                <TableCell align="right">
                  {Util.formatNumber( data[2].totalIndirectExpenses )}
                </TableCell>
                <TableCell />
                <TableCell align="right">
                  {Util.formatNumber( data[3].totalIndirectExpenses )}
                </TableCell>
                <TableCell />
                <TableCell align="right">
                  {Util.formatNumber( data[4].totalIndirectExpenses )}
                </TableCell>
                <TableCell />
              </TableRow>
              <TableRow>
                <TableCell>
                  <IntlMessages id="ExtraordinaryIncomeOrLosses" />
                </TableCell>
                <TableCell align="right">
                  {Util.formatNumber(
                    data[0].settlementCost - data[0].liquidatedProductsCost,
                  )}
                </TableCell>
                <TableCell />
                <TableCell align="right">
                  {Util.formatNumber(
                    data[1].settlementCost - data[1].liquidatedProductsCost,
                  )}
                </TableCell>
                <TableCell />
                <TableCell align="right">
                  {Util.formatNumber(
                    data[2].settlementCost - data[2].liquidatedProductsCost,
                  )}
                </TableCell>
                <TableCell />
                <TableCell align="right">
                  {Util.formatNumber(
                    data[3].settlementCost - data[3].liquidatedProductsCost,
                  )}
                </TableCell>
                <TableCell />
                <TableCell align="right">
                  {Util.formatNumber(
                    data[4].settlementCost - data[4].liquidatedProductsCost,
                  )}
                </TableCell>
                <TableCell />
              </TableRow>

              {/*
                <TableRow className="bg-gray-3>
                  <TableCell "><IntlMessages id="TotalExpenses" /></TableCell>
                  <TableCell className="text-right bg-gray-3">
                  {Util.formatNumber(  data[0].totalExpenses  )}</TableCell>
                  <TableCell className="text-right bg-gray-3">
                  {Util.formatNumber(  data[1].totalExpenses  )}</TableCell>
                  <TableCell className="text-right bg-gray-3">
                  {Util.formatNumber(  data[2].totalExpenses  )}</TableCell>
                  <TableCell className="text-right bg-gray-3">
                  {Util.formatNumber(  data[3].totalExpenses  )}</TableCell>
                  <TableCell className="text-right bg-gray-3">
                  {Util.formatNumber(  data[4].totalExpenses  )}</TableCell>
                </TableRow>
              */}
              <TableRow className="bg-gray-3">
                <TableCell>
                  <IntlMessages id="EarningsBeforeTax" />
                </TableCell>
                <TableCell align="right">
                  {Util.formatNumber( data[0].earningBeforeTaxes )}
                </TableCell>
                <TableCell align="right">
                  {Util.formatNumberFixedDec(
                    ( data[0].earningBeforeTaxes
                      / data[0].totalRevenuesFromSales )
                      * 100,
                  )}
                  %
                </TableCell>

                <TableCell align="right">
                  {Util.formatNumber( data[1].earningBeforeTaxes )}
                </TableCell>
                <TableCell align="right">
                  {Util.formatNumberFixedDec(
                    ( data[1].earningBeforeTaxes
                      / data[1].totalRevenuesFromSales )
                      * 100,
                  )}
                  %
                </TableCell>
                <TableCell align="right">
                  {Util.formatNumber( data[2].earningBeforeTaxes )}
                </TableCell>
                <TableCell align="right">
                  {Util.formatNumberFixedDec(
                    ( data[2].earningBeforeTaxes
                      / data[2].totalRevenuesFromSales )
                      * 100,
                  )}
                  %
                </TableCell>
                <TableCell align="right">
                  {Util.formatNumber( data[3].earningBeforeTaxes )}
                </TableCell>
                <TableCell align="right">
                  {Util.formatNumberFixedDec(
                    ( data[3].earningBeforeTaxes
                      / data[3].totalRevenuesFromSales )
                      * 100,
                  )}
                  %
                </TableCell>
                <TableCell align="right">
                  {Util.formatNumber( data[4].earningBeforeTaxes )}
                </TableCell>
                <TableCell align="right">
                  {Util.formatNumberFixedDec(
                    ( data[4].earningBeforeTaxes
                      / data[4].totalRevenuesFromSales )
                      * 100,
                  )}
                  %
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  <IntlMessages id="Incometaxes" />
                </TableCell>
                <TableCell align="right">
                  {Util.formatNumber( data[0].incomeTaxes )}
                </TableCell>
                <TableCell />
                <TableCell align="right">
                  {Util.formatNumber( data[1].incomeTaxes )}
                </TableCell>
                <TableCell />
                <TableCell align="right">
                  {Util.formatNumber( data[2].incomeTaxes )}
                </TableCell>
                <TableCell />
                <TableCell align="right">
                  {Util.formatNumber( data[3].incomeTaxes )}
                </TableCell>
                <TableCell />
                <TableCell align="right">
                  {Util.formatNumber( data[4].incomeTaxes )}
                </TableCell>
                <TableCell />
              </TableRow>
              <TableRow className="bg-gray-3">
                <TableCell>
                  <IntlMessages id="Netearnings" />
                </TableCell>
                <TableCell align="right">
                  {Util.formatNumber( data[0].netEarnings )}
                </TableCell>
                <TableCell align="right">
                  {Util.formatNumberFixedDec(
                    ( data[0].netEarnings / data[0].totalRevenuesFromSales )
                      * 100.0,
                  )}
                  %
                </TableCell>
                <TableCell align="right">
                  {Util.formatNumber( data[1].netEarnings )}
                </TableCell>
                <TableCell align="right">
                  {Util.formatNumberFixedDec(
                    ( data[1].netEarnings / data[1].totalRevenuesFromSales ) * 100,
                  )}
                  %
                </TableCell>
                <TableCell align="right">
                  {Util.formatNumber( data[2].netEarnings )}
                </TableCell>
                <TableCell align="right">
                  {Util.formatNumberFixedDec(
                    ( data[2].netEarnings / data[2].totalRevenuesFromSales ) * 100,
                  )}
                  %
                </TableCell>
                <TableCell align="right">
                  {Util.formatNumber( data[3].netEarnings )}
                </TableCell>
                <TableCell align="right">
                  {Util.formatNumberFixedDec(
                    ( data[3].netEarnings / data[3].totalRevenuesFromSales ) * 100,
                  )}
                  %
                </TableCell>
                <TableCell align="right">
                  {Util.formatNumber( data[4].netEarnings )}
                </TableCell>
                <TableCell align="right">
                  {Util.formatNumberFixedDec(
                    ( data[4].netEarnings / data[4].totalRevenuesFromSales )
                      * 100.0,
                  )}
                  %
                </TableCell>
              </TableRow>

              {/* END INDIRECT EXPENSES */}
              <TableRow className="subtitle">
                <TableCell />
                <TableCell colSpan={2}>{data[0].workgroup.name}</TableCell>
                <TableCell colSpan={2}>{data[1].workgroup.name}</TableCell>
                <TableCell colSpan={2}>{data[2].workgroup.name}</TableCell>
                <TableCell colSpan={2}>{data[3].workgroup.name}</TableCell>
                <TableCell colSpan={2}>{data[4].workgroup.name}</TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </CardBox>
      )}
    </DialogContent>
  </Dialog>
);

export default CompModal;
