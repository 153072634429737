import React from 'react';

import CardBox from 'components/custom_v2/CardBox';
import * as am4core from '@amcharts/amcharts4/core';
import * as am4charts from '@amcharts/amcharts4/charts';
import _, { get } from 'lodash';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import DashboardStudentService from 'services/DashboardStudent';
import ChartModal from 'routes/App/components/User/Chart';
import SessionService from 'services/Session';
import Util from 'utils/Util';
import IntlMessages from 'utils/IntlMessages';
import {
  Grid,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@material-ui/core';
import SettingsActions from 'store/reducers/Settings';
import HoverTitle from 'components/custom_v2/HoverTitle';
import ExcellPL from 'components/custom_v2/ExcellPL';
import HeadConfig from 'components/custom/HeadConfig';
import KotlerCardHeader from 'components/custom/KotlerCardHeader';
import FeatherIcon from 'components/custom/FeatherIcon';
import KotlerSectionHeader from 'components/custom/KoTlerSectionHeader';
import HeaderImage from 'assets/images/kotler/sectionHeaders/info-company.jpeg';
import CompModal from './CompModal';
import WorkgroupTeamTable from './WorkgroupTeamTable';

class CompanyChart extends React.Component {
  state = {
    CompModalB: false,
    chart1Modal: false,
    chart2Modal: false,
    chart3Modal: false,
    cash: 0,
    // marketingBudget: 0,
    table1: [],
  };
  seriesTitle1 = [];
  seriesTitle2 = [];
  seriesTitle3 = [];
  exportFileName = 'P&L';
  mkmode = {};
  componentDidMount() {
    const { user } = this.props;
    this.mkmode = user.activeSession.fields;
    this.isEnterprise = user.activeSession.isEnterprise;

    this.loadComponent();
  }

  componentDidUpdate( nextProps ) {
    const { locale } = this.props;
    if ( nextProps.locale !== locale ) {
      am4core.disposeAllCharts();
      this.loadComponent();
    }
  }

  componentWillUnmount() {
    am4core.disposeAllCharts();
  }

  toggleCompModal = () => {
    this.setState( previousState => ( { CompModalB: !previousState.CompModalB } ) );
  };

  toggleChart1Modal = () => {
    this.setState( previousState => ( { chart1Modal: !previousState.chart1Modal } ) );
  };

  toggleChart2Modal = () => {
    this.setState( previousState => ( { chart2Modal: !previousState.chart2Modal } ) );
  };

  toggleChart3Modal = () => {
    this.setState( previousState => ( { chart3Modal: !previousState.chart3Modal } ) );
  };

  async loadComponent() {
    const { user, toggleLoading, intl } = this.props;
    toggleLoading( true );
    this.license = _.find( user.licenses, { session: { id: user.activeSession.id } } );
    await SessionService.getSessionCurrentWorkgroupRound(
      user.activeSession.id,
    ).then( ( response ) => {
      if ( response.ok ) {
        // console.log (  response.data );
        if ( response.data.cash ) {
          this.setState( {
            cash: response.data.cash,
            // marketingBudget: response.data.marketingBudget,
            outstandingDebt: response.data.outstandingDebt,
          } );
        }
      }
    } );
    await DashboardStudentService.getStockValueAllWorkgroup(
      user.activeSession.id,
    ).then( ( response ) => {
      if ( response.ok ) {
        const roundsX = [];
        // const rounds2X = [];
        _.map( response.data, ( item ) => {
          _.map( item.historical, ( round ) => {
            const exist = _.findIndex( roundsX, { id: round.round.id } );
            if ( exist === -1 ) {
              const shortName = round.round.shortName.split( ' ' );
              const dataToPush = {
                attribute: intl.formatMessage(
                  { id: shortName[0] },
                  { value: shortName[1] },
                ),
                id: round.round.id,
              };
              dataToPush[item.id] = round.stockValue;
              roundsX.push( dataToPush );
            } else {
              roundsX[exist][item.id] = round.stockValue;
              // TO CHECK, Math.floor( ( Math.random() * 1000 ) + 1 );
            }
          } );
          this.seriesTitle1.push( item.name );
        } );

        this.config3 = {
          data: roundsX,
          type: am4charts.XYChart,
          xAxes: [
            {
              type: 'CategoryAxis',
              dataFields: { category: 'attribute' },
              title: { text: intl.formatMessage( { id: 'Rounds' } ) },
            },
          ],
          yAxes: [
            {
              type: 'ValueAxis',
              title: { text: intl.formatMessage( { id: 'Price' } ) },
            },
          ],
          series: _.map( response.data, itemX => ( {
            type: 'LineSeries',
            dataFields: {
              valueY: itemX.id,
              categoryX: 'attribute',
            },
            name: itemX.name,
            strokeWidth: 2,
          } ) ),
        };

        this.chart3 = am4core.createFromConfig(
          _.cloneDeep( this.config3 ),
          'chartStockdiv',
        );
      }
    } );
    await DashboardStudentService.getHistoricalChart(
      user.activeSession.id,
      this.license.workGroup.id,
      { limit: -1 },
    ).then( ( response ) => {
      if ( response.ok ) {
        this.seriesTitles2 = [intl.formatMessage( { id: 'Revenues' } )];
        this.config = {
          data: _.map( response.data.historical, item => ( {
            category: intl.formatMessage(
              { id: item.round.shortName.split( ' ' )[0] },
              { value: item.round.shortName.split( ' ' )[1] },
            ),
            revenues: item.revenues,
          } ) ),
          type: am4charts.XYChart,
          xAxes: [
            {
              type: 'CategoryAxis',
              dataFields: { category: 'category' },
              title: { text: intl.formatMessage( { id: 'Rounds' } ) },
            },
          ],
          yAxes: [
            {
              type: 'ValueAxis',
              title: { text: intl.formatMessage( { id: 'Revenue' } ) },
            },
          ],
          series: [
            {
              type: 'LineSeries',
              dataFields: {
                valueY: 'revenues',
                categoryX: 'category',
              },
            },
          ],
        };
        this.chart = am4core.createFromConfig(
          _.cloneDeep( this.config ),
          'chartdiv',
        );
        this.seriesTitle3 = [intl.formatMessage( { id: 'Earnings' } )];
        this.config2 = {
          data: _.map( response.data.historical, item => ( {
            category: intl.formatMessage(
              { id: item.round.shortName.split( ' ' )[0] },
              { value: item.round.shortName.split( ' ' )[1] },
            ),
            earnings: item.earnings,
          } ) ),
          type: am4charts.XYChart,
          xAxes: [
            {
              type: 'CategoryAxis',
              dataFields: { category: 'category' },
              title: { text: intl.formatMessage( { id: 'Rounds' } ) },
            },
          ],
          yAxes: [
            {
              type: 'ValueAxis',
              title: { text: intl.formatMessage( { id: 'Earnings' } ) },
            },
          ],
          series: [
            {
              type: 'LineSeries',
              dataFields: {
                valueY: 'earnings',
                categoryX: 'category',
              },
            },
          ],
        };
        this.chart2 = am4core.createFromConfig(
          _.cloneDeep( this.config2 ),
          'chartEarningsdiv',
        );

        this.setState( {
          revenuesFromGoodsSold: response.data.revenuesFromGoodsSold,
          revenuesFromServicesSold: response.data.revenuesFromServicesSold,
          revenuesFromBundlesSold: response.data.revenuesFromBundlesSold,
          totalRevenuesFromSales: response.data.totalRevenuesFromSales,
          costOfGoodsSold: response.data.costOfGoodsSold,
          costOfBundlesSold: response.data.costOfBundlesSold,
          totalCostsFormSales: response.data.totalCostsFormSales,
          generalAdministrative: response.data.generalAdministrative,
          marketingCost: response.data.marketingCost,
          directSalesCost: response.data.directSalesCost,
          stockHandlingCosts: response.data.stockHandlingCosts,
          rd: response.data.rd,
          interestsFromLoans: response.data.interestsFromLoans,
          totalIndirectExpenses: response.data.totalIndirectExpenses,
          settlementCost: response.data.settlementCost,
          liquidatedProductsCost: response.data.liquidatedProductsCost,
          // totalExpenses: response.data.totalExpenses,
          costOfServicesSold: response.data.costOfServicesSold,
          earningBeforeTaxes: response.data.earningBeforeTaxes,
          incomeTaxes: response.data.incomeTaxes,
          netEarnings: response.data.netEarnings,
          grossProfit: response.data.grossProfit,
          balanceData: response.data.balance,
          /*
            productSales: response.data.productSales,
            serviceSales: response.data.serviceSales,
            bundleSales: response.data.bundleSales,
            totalOperatingRevenue: response.data.totalOperatingRevenue,
            costOfGoodSold: response.data.costOfGoodsSold,
            inventoryCost: response.data.inventoryCost,
            marketingExpenses: response.data.marketingExpenses,
            productDevelopment: response.data.productDevelopment,
            loanInterests: response.data.loanInterests,
             */
        } );
        // eslint-disable-next-line max-len
        DashboardStudentService.getPLComparison( user.activeSession.id, { limit: -1 } ).then( ( responsePL ) => {
          if ( responsePL.ok ) {
            this.setState( { dataExcel: responsePL.data } );
            const shortName = user.activeSession.currentRound.shortName.split(
              ' ',
            );
            // eslint-disable-next-line max-len
            const monthRond = intl.formatMessage(
              { id: shortName[0] },
              { value: shortName[1] },
            );
            this.exportFileName = `P&L ${monthRond}`;
          }
        } );
      }
    } );

    await SessionService.getSession( user.activeSession.id ).then( ( response ) => {
      if ( response.ok ) {
        if ( response.data.workGroups ) {
          const stock = _.map( response.data.workGroups, item => ( {
            id: item.id,
            name: item.name,
            points: item.points,
            percentagePoints: item.percentagePoints,
            newStockValue: item.newStockValue,
            position: item.position,
          } ) );

          stock.sort( ( a, b ) => a.position - b.position );

          this.setState( { stock } );
        }
      }
    } );
    toggleLoading( false );
  }
  render() {
    const {
      revenuesFromGoodsSold,
      revenuesFromServicesSold,
      revenuesFromBundlesSold,
      totalRevenuesFromSales,
      costOfGoodsSold,
      totalCostsFormSales,
      costOfBundlesSold,
      generalAdministrative,
      marketingCost,
      directSalesCost,
      stockHandlingCosts,
      rd,
      interestsFromLoans,
      totalIndirectExpenses,
      CompModalB,
      chart1Modal,
      chart2Modal,
      chart3Modal,
      cash,
      outstandingDebt,
      table1,
      costOfServicesSold,
      grossProfit,
      earningBeforeTaxes,
      incomeTaxes,
      netEarnings,
      stock,
      dataExcel,
      balanceData,
      liquidatedProductsCost,
      settlementCost,
    } = this.state;
    const { user } = this.props;

    this.mkmode = user.activeSession.fields;

    this.isEnterprise = user.activeSession.isEnterprise;
    const canBalance = get( this.mkmode, 'information.company.balance' ) !== false;

    return (
      <>
        <HeadConfig
          breadcrumbsLinks={[
            {
              url: '/',
              name: 'HomeLink',
            },
            { name: 'InformationDashboard-company' },
          ]}
        />
        <KotlerSectionHeader title="company" image={HeaderImage} />
        <Grid container>
          <Grid item xs={12} md={5} style={{ overflowX: 'auto' }}>
            <CardBox
              childrenStyle="pb-0"
              styleName="px-2 h-100 pb-0"
              header={(
                <KotlerCardHeader
                  minHeight="0px"
                  title="ProfitLossStatement"
                  rightComponent={(
                    <div className="d-flex">
                      <HoverTitle background="#003594" id="ClickForComparison">
                        <IconButton
                          color="primary"
                          className="square-icon-button bounce-infinite"
                          onClick={this.toggleCompModal}
                        >
                          <FeatherIcon
                            icon="fileText"
                            color="primary.main"
                            size="18px"
                          />
                        </IconButton>
                      </HoverTitle>
                      <ExcellPL data={dataExcel} name={this.exportFileName} />
                    </div>
                  )}
                />
              )}
            >
              <div>
                {!!(
                  this.mkmode.information && this.mkmode.information.company
                ) && (
                  <Table className="kotler-table-info-details  kotler-table-info-sm kotler-table-profit-and-lost">
                    <TableHead />
                    <TableBody>
                      {/* REVENUES */}
                      <TableRow className="subtitle">
                        <TableCell colSpan={2}>
                          <HoverTitle
                            id="ProfitLossStatement.hover"
                            placement="right"
                          >
                            <IntlMessages id="Revenues" />
                          </HoverTitle>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>
                          <HoverTitle
                            id="ProfitLossStatement.hover"
                            placement="right"
                          >
                            <span>
                              <IntlMessages id="RevenuesFromGoodSold" />
                            </span>
                          </HoverTitle>
                        </TableCell>
                        <TableCell align="right">
                          {Util.formatNumber( revenuesFromGoodsSold )}
                        </TableCell>
                        <TableCell />
                      </TableRow>
                      {this.mkmode.information.company.profitAndLoss
                        .revenuesFromServ && (
                        <TableRow>
                          <TableCell>
                            <HoverTitle
                              id="ProfitLossStatement.hover"
                              placement="right"
                            >
                              <span>
                                <IntlMessages id="RevenuesFromServ" />
                              </span>
                            </HoverTitle>
                          </TableCell>
                          <TableCell align="right">
                            {Util.formatNumber( revenuesFromServicesSold )}
                          </TableCell>
                          <TableCell />
                        </TableRow>
                      )}
                      {this.mkmode.information.company.profitAndLoss
                        .revenuesFromBundlesSales && (
                        <TableRow>
                          <TableCell>
                            <HoverTitle
                              id="ProfitLossStatement.hover"
                              placement="right"
                            >
                              <span>
                                <IntlMessages id="RevenuesFromBundlesSales" />
                              </span>
                            </HoverTitle>
                          </TableCell>
                          <TableCell align="right">
                            {Util.formatNumber( revenuesFromBundlesSold )}
                          </TableCell>
                          <TableCell />
                        </TableRow>
                      )}
                      <TableRow className=" bg-gray-3">
                        <TableCell>
                          <HoverTitle
                            id="ProfitLossStatement.hover"
                            placement="right"
                          >
                            <span>
                              <IntlMessages id="TotalRevenuesFromSales" />
                            </span>
                          </HoverTitle>
                        </TableCell>
                        <TableCell align="right">
                          {Util.formatNumber( totalRevenuesFromSales )}
                        </TableCell>
                        <TableCell />
                      </TableRow>
                      {/* DIRECT EXPENSES */}
                      <TableRow className="subtitle">
                        <TableCell colSpan={2}>
                          <HoverTitle
                            id="ProfitLossStatement.hover"
                            placement="right"
                          >
                            <IntlMessages id="DirectExpenses" />
                          </HoverTitle>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>
                          <HoverTitle
                            id="ProfitLossStatement.hover"
                            placement="right"
                          >
                            <span>
                              <IntlMessages id="CostOfGoodsSold" />
                            </span>
                          </HoverTitle>
                        </TableCell>
                        <TableCell align="right">
                          {Util.formatNumber( costOfGoodsSold )}
                        </TableCell>
                        <TableCell />
                      </TableRow>
                      {this.mkmode.information.company.profitAndLoss
                        .costOfServicesSold && (
                        <TableRow>
                          <TableCell>
                            <HoverTitle
                              id="ProfitLossStatement.hover"
                              placement="right"
                            >
                              <span>
                                <IntlMessages id="CostOfServicesSold" />
                              </span>
                            </HoverTitle>
                          </TableCell>
                          <TableCell align="right">
                            {Util.formatNumber( costOfServicesSold )}
                          </TableCell>
                          <TableCell />
                        </TableRow>
                      )}
                      {this.mkmode.information.company.profitAndLoss
                        .costOfBundlesSold && (
                        <TableRow>
                          <TableCell>
                            <HoverTitle
                              id="ProfitLossStatement.hover"
                              placement="right"
                            >
                              <span>
                                <IntlMessages id="CostOfBundlesSold" />
                              </span>
                            </HoverTitle>
                          </TableCell>
                          <TableCell align="right">
                            {Util.formatNumber( costOfBundlesSold )}
                          </TableCell>
                          <TableCell />
                        </TableRow>
                      )}

                      <TableRow className=" bg-gray-3">
                        <TableCell>
                          <HoverTitle
                            id="ProfitLossStatement.hover"
                            placement="right"
                          >
                            <span>
                              <IntlMessages id="TotalCostFromSales" />
                            </span>
                          </HoverTitle>
                        </TableCell>
                        <TableCell align="right">
                          {Util.formatNumber( totalCostsFormSales )}
                        </TableCell>
                        <TableCell />
                      </TableRow>
                      {/* INDIRECT EXPENSES */}
                      <TableRow className="bg-gray-3">
                        <TableCell>
                          <HoverTitle
                            id="ProfitLossStatement.hover"
                            placement="right"
                          >
                            <IntlMessages id="Grossprofit" />
                          </HoverTitle>
                        </TableCell>
                        <TableCell align="right">
                          {Util.formatNumber( grossProfit )}
                        </TableCell>

                        <TableCell align="right">
                          {totalRevenuesFromSales
                            && Util.formatNumberFixedDec(
                              ( grossProfit / totalRevenuesFromSales ) * 100,
                            )}
                          {totalRevenuesFromSales && '%'}
                        </TableCell>
                      </TableRow>
                      <TableRow className="subtitle">
                        <TableCell colSpan={2}>
                          <HoverTitle
                            id="ProfitLossStatement.hover"
                            placement="right"
                          >
                            <IntlMessages id="IndirectExpenses" />
                          </HoverTitle>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>
                          <HoverTitle
                            id="ProfitLossStatement.hover"
                            placement="right"
                          >
                            <span>
                              <IntlMessages id="GeneralAdministrative" />
                            </span>
                          </HoverTitle>
                        </TableCell>
                        <TableCell align="right">
                          {Util.formatNumber( generalAdministrative )}
                        </TableCell>
                        <TableCell />
                      </TableRow>
                      <TableRow>
                        <TableCell>
                          <HoverTitle
                            id="ProfitLossStatement.hover"
                            placement="right"
                          >
                            <span>
                              <IntlMessages id="MarketingCosts" />
                            </span>
                          </HoverTitle>
                        </TableCell>
                        <TableCell align="right">
                          {Util.formatNumber( marketingCost )}
                        </TableCell>
                        <TableCell />
                      </TableRow>
                      <TableRow>
                        <TableCell>
                          <HoverTitle
                            id="ProfitLossStatement.hover"
                            placement="right"
                          >
                            <span>
                              <IntlMessages id="DirectSalesCosts" />
                            </span>
                          </HoverTitle>
                        </TableCell>
                        <TableCell align="right">
                          {Util.formatNumber( directSalesCost )}
                        </TableCell>
                        <TableCell />
                      </TableRow>
                      <TableRow>
                        <TableCell>
                          <HoverTitle
                            id="ProfitLossStatement.hover"
                            placement="right"
                          >
                            <span>
                              <IntlMessages id="StockHandlingCosts" />
                            </span>
                          </HoverTitle>
                        </TableCell>
                        <TableCell align="right">
                          {Util.formatNumber( stockHandlingCosts )}
                        </TableCell>
                        <TableCell />
                      </TableRow>
                      <TableRow>
                        <TableCell>
                          <HoverTitle
                            id="ProfitLossStatement.hover"
                            placement="right"
                          >
                            <span>
                              <IntlMessages id="RandD" />
                            </span>
                          </HoverTitle>
                        </TableCell>
                        <TableCell align="right">
                          {Util.formatNumber( rd )}
                        </TableCell>
                        <TableCell />
                      </TableRow>

                      <TableRow>
                        <TableCell>
                          <HoverTitle
                            id="ProfitLossStatement.hover"
                            placement="right"
                          >
                            <span>
                              <IntlMessages id="InterestsFromLoans" />
                            </span>
                          </HoverTitle>
                        </TableCell>
                        <TableCell align="right">
                          {Util.formatNumber( interestsFromLoans )}
                        </TableCell>
                        <TableCell />
                      </TableRow>
                      <TableRow className="bg-gray-3">
                        <TableCell>
                          <HoverTitle
                            id="ProfitLossStatement.hover"
                            placement="right"
                          >
                            <span>
                              <IntlMessages id="TotalIndirectsExpenses" />
                            </span>
                          </HoverTitle>
                        </TableCell>
                        <TableCell align="right">
                          {Util.formatNumber( totalIndirectExpenses )}
                        </TableCell>
                        <TableCell />
                      </TableRow>
                      <TableRow>
                        <TableCell>
                          <HoverTitle
                            id="ProfitLossStatement.hover"
                            placement="right"
                          >
                            <span>
                              <IntlMessages id="ExtraordinaryIncomeOrLosses" />
                            </span>
                          </HoverTitle>
                        </TableCell>
                        <TableCell align="right">
                          {settlementCost
                            && Util.formatNumber(
                              settlementCost - liquidatedProductsCost,
                            )}
                        </TableCell>
                        <TableCell />
                      </TableRow>
                      <TableRow className="bg-gray-3">
                        <TableCell>
                          <HoverTitle
                            id="ProfitLossStatement.hover"
                            placement="right"
                          >
                            <IntlMessages id="EarningsBeforeTax" />
                          </HoverTitle>
                        </TableCell>
                        <TableCell align="right">
                          <b>{Util.formatNumber( earningBeforeTaxes )}</b>
                        </TableCell>
                        <TableCell align="right">
                          <b>
                            {totalRevenuesFromSales
                              && Util.formatNumberFixedDec(
                                ( earningBeforeTaxes / totalRevenuesFromSales )
                                  * 100,
                              )}
                            {totalRevenuesFromSales && '%'}
                          </b>
                        </TableCell>
                      </TableRow>

                      <TableRow>
                        <TableCell>
                          <HoverTitle
                            id="ProfitLossStatement.hover"
                            placement="right"
                          >
                            <span>
                              <IntlMessages id="Incometaxes" />
                            </span>
                          </HoverTitle>
                        </TableCell>
                        <TableCell align="right">
                          {Util.formatNumber( incomeTaxes )}
                        </TableCell>
                        <TableCell />
                      </TableRow>
                      <TableRow className="bg-gray-3">
                        <TableCell>
                          <HoverTitle
                            id="ProfitLossStatement.hover"
                            placement="right"
                          >
                            <IntlMessages id="Netearnings" />
                          </HoverTitle>
                        </TableCell>
                        <TableCell align="right">
                          {Util.formatNumber( netEarnings )}
                        </TableCell>
                        <TableCell align="right">
                          {totalRevenuesFromSales
                            && Util.formatNumberFixedDec(
                              ( netEarnings / totalRevenuesFromSales ) * 100,
                            )}
                          {totalRevenuesFromSales && '%'}
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                )}
              </div>
            </CardBox>
          </Grid>
          <Grid item md={7}>
            <Grid container className="h-100">
              <Grid item xs={12} md={6} style={{ overflowX: 'auto' }}>
                <CardBox styleName="px-2 h-100 pb-0" header={<div />}>
                  <div onClick={this.toggleChart1Modal}>
                    <KotlerCardHeader minHeight="0px" title="Revenues" />
                    <div
                      className="jr-link"
                      id="chartdiv"
                      style={{ width: '100%', height: '250px' }}
                    />
                  </div>

                  <div>
                    {!!table1 && table1.length > 0 && (
                      <div>
                        <h5>
                          <b>TITLE</b>
                        </h5>
                      </div>
                    )}
                    {_.map( table1, item => (
                      <div>{`${item.text} ${item.val}`}</div>
                    ) )}
                  </div>

                  <div className="mt-3" onClick={this.toggleChart2Modal}>
                    <KotlerCardHeader
                      minHeight="54px"
                      title="Company.Earnings"
                    />
                    {' '}
                    <div
                      className="jr-link"
                      id="chartEarningsdiv"
                      style={{ width: '100%', height: '250px' }}
                    />
                  </div>
                </CardBox>
              </Grid>
              <Grid item xs={12} md={6} style={{ overflowX: 'auto' }}>
                <CardBox
                  styleName="px-3 h-100 pb-0"
                  header={(
                    <KotlerCardHeader
                      minHeight="0px"
                      className="px-0"
                      title="StockPrices"
                    />
                  )}
                  headerHover={{ id: 'stockPrices.hoverHeader' }}
                  hover={{ id: 'stockPrices.hover' }}
                >
                  <div style={{ minHeight: 250, overflowX: 'auto' }}>
                    <Table className="kotler-table-info-details kotler-table-info-md ">
                      <TableBody>
                        {_.map( stock, item => (
                          <TableRow key={item.id}>
                            <TableCell
                              className={
                                this.license.workGroup.id === item.id
                                  ? 'font-weight-bold text-primary text-nowrap'
                                  : 'text-nowrap'
                              }
                              style={{ width: '130px' }}
                            >
                              {item.name}
                            </TableCell>
                            <TableCell
                              className={
                                this.license.workGroup.id === item.id
                                  ? 'font-weight-bold text-primary text-nowrap'
                                  : null
                              }
                            >
                              {Util.formatCurrency( item.newStockValue )}
                            </TableCell>
                            <TableCell
                              className={
                                this.license.workGroup.id === item.id
                                  ? 'font-weight-bold text-primary '
                                  : null
                              }
                            >
                              {`${item.percentagePoints}%`}
                            </TableCell>
                            {item && item.percentagePoints < 0 && (
                              <TableCell className="pr-2">
                                <i className="fa fa-arrow-down text-danger mx-2" />
                              </TableCell>
                            )}
                            {item && item.percentagePoints > 0 && (
                              <TableCell className="pr-2">
                                <i className="fa fa-arrow-up text-primary mx-2" />
                              </TableCell>
                            )}
                            {item && item.percentagePoints === 0 && (
                              <TableCell className="pr-2">
                                <i className="fa fa-arrow-right text-dark " />
                              </TableCell>
                            )}
                          </TableRow>
                        ) )}
                      </TableBody>
                    </Table>
                  </div>
                  <div className="mt-3" onClick={this.toggleChart3Modal}>
                    <KotlerCardHeader
                      minHeight="54px"
                      title="StockPricesTrend"
                    />
                    <div
                      className="jr-link"
                      id="chartStockdiv"
                      style={{ width: '100%', height: '250px' }}
                    />
                  </div>
                </CardBox>
              </Grid>
            </Grid>
          </Grid>
          {!!balanceData && canBalance && (
            <Grid item xs={12} md={8} className="mt-5">
              <CardBox
                styleName="px-2"
                header={(
                  <KotlerCardHeader
                    minHeight="0px"
                    title="company.balance"
                    className="p-0"
                  />
                )}
              >
                <Grid container>
                  <Grid item xs={12} sm={6}>
                    <Table className="kotler-table-info-details kotler-table-info-sm">
                      <TableHead />
                      <TableBody>
                        <TableRow>
                          <TableCell colSpan={2}>
                            <IntlMessages id="balance.assets" />
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>
                            <IntlMessages id="balance.cash" />
                          </TableCell>
                          <TableCell align="right">
                            {Util.formatNumber( balanceData.cash )}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>
                            <HoverTitle
                              id="balance.inventoryValue.hover"
                              placement="right"
                            >
                              <span>
                                <IntlMessages id="balance.inventoryValue" />
                              </span>
                            </HoverTitle>
                          </TableCell>
                          <TableCell align="right">
                            {Util.formatNumber( balanceData.inventoryValue )}
                          </TableCell>
                        </TableRow>
                        {user.activeSession.distributionType !== 'studio' && (
                          <TableRow>
                            <TableCell>
                              <HoverTitle
                                id="balance.receivables.hover"
                                placement="right"
                              >
                                <span>
                                  <IntlMessages id="balance.receivables" />
                                </span>
                              </HoverTitle>
                            </TableCell>
                            <TableCell align="right">
                              {Util.formatNumber(
                                balanceData.serviceFeesReceivable
                                  + balanceData.bundleFeesReceivable,
                              )}
                            </TableCell>
                          </TableRow>
                        )}

                        <TableRow>
                          <TableCell>
                            <strong>
                              <IntlMessages id="balance.totalAssets" />
                            </strong>
                          </TableCell>
                          <TableCell align="right">
                            <strong>
                              {Util.formatNumber(
                                balanceData.cash
                                  + balanceData.inventoryValue
                                  + balanceData.serviceFeesReceivable
                                  + balanceData.bundleFeesReceivable,
                              )}
                            </strong>
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Table className="kotler-table-info-details kotler-table-info-sm">
                      <TableHead />
                      <TableBody>
                        <TableRow>
                          <TableCell>
                            <IntlMessages id="balance.patrimony" />
                          </TableCell>
                          <TableCell align="right">
                            {Util.formatNumber( balanceData.patrimony )}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell style={{ fontWeight: 'bolder' }}>
                            <strong>
                              <IntlMessages id="balance.liabilities" />
                            </strong>
                          </TableCell>
                          <TableCell style={{ fontWeight: 'bolder' }}>
                            <strong>&nbsp;</strong>
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>
                            <HoverTitle
                              id="outstandingDebt.hover"
                              placement="right"
                            >
                              <span>
                                <IntlMessages id="balance.loans" />
                              </span>
                            </HoverTitle>
                          </TableCell>
                          <TableCell align="right">
                            {Util.formatNumber( balanceData.loans )}
                          </TableCell>
                        </TableRow>
                        {user.activeSession.distributionType !== 'studio' && (
                          <TableRow>
                            <TableCell>
                              <HoverTitle
                                id="balance.serviceFeesPayable.hover"
                                placement="right"
                              >
                                <span>
                                  <IntlMessages id="balance.serviceFeesPayable" />
                                </span>
                              </HoverTitle>
                            </TableCell>
                            <TableCell align="right">
                              {Util.formatNumber(
                                balanceData.serviceFeesPayable,
                              )}
                            </TableCell>
                          </TableRow>
                        )}
                        <TableRow>
                          <TableCell>
                            <strong>
                              <IntlMessages id="balance.totalLiabilities" />
                            </strong>
                          </TableCell>
                          <TableCell align="right">
                            <strong>
                              {Util.formatNumber(
                                balanceData.loans
                                  + balanceData.serviceFeesPayable,
                              )}
                            </strong>
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>
                            <strong>
                              <IntlMessages id="balance.totalLiabilitiesAndPatrimony" />
                            </strong>
                          </TableCell>
                          <TableCell align="right">
                            <strong>
                              {Util.formatNumber(
                                balanceData.loans
                                  + balanceData.serviceFeesPayable
                                  + balanceData.patrimony,
                              )}
                            </strong>
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </Grid>
                </Grid>
              </CardBox>
            </Grid>
          )}
          {canBalance && (
            <Grid item xs={12} md={4} className="mt-5">
              <CardBox
                styleName="px-2 pt-2 h-100 d-flex flex-column"
                childrenStyle="flex-grow-1 d-flex flex-column justify-content-around"
                disableHeader
              >
                <Table className="kotler-table-info-details">
                  <TableBody>
                    <TableRow>
                      <TableCell>
                        <HoverTitle
                          id="outstandingDebt.hover"
                          placement="right"
                        >
                          <span>
                            <IntlMessages id="outstandingDebt" />
                          </span>
                        </HoverTitle>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>
                        <HoverTitle
                          id="outstandingDebt.hover"
                          placement="right"
                        >
                          <span>
                            {'$ '}
                            {Util.formatCurrency( outstandingDebt )}
                          </span>
                        </HoverTitle>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
                <Table className="kotler-table-info-details">
                  <TableBody>
                    <TableRow>
                      <TableCell>
                        <IntlMessages id="cashavailability" />
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>
                        {'$ '}
                        {Util.formatCurrency( cash )}
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </CardBox>
            </Grid>
          )}
          <Grid item xs={12} md={8}>
            <WorkgroupTeamTable />
          </Grid>
        </Grid>

        {/** <CardBox heading="company.availableMarketingBudget" headerBg="bg-product-laptop">
              <Row>
                <Col className="text-center">
                  <b>
                    {Util.formatCurrency( marketingBudget )}
                  </b>
                </Col>
              </Row>
            </CardBox>
            */}

        <CompModal
          open={CompModalB}
          title="PLComparison"
          onClose={this.toggleCompModal}
          data={dataExcel}
          mkmode={this.mkmode}
        />
        <ChartModal
          open={chart1Modal}
          title="Revenues"
          onClose={this.toggleChart1Modal}
          seriesTitle={this.seriesTitles2}
          config={this.config}
        />
        <ChartModal
          open={chart2Modal}
          title="Earnings"
          onClose={this.toggleChart2Modal}
          seriesTitle={this.seriesTitle3}
          config={this.config2}
        />
        <ChartModal
          open={chart3Modal}
          title="StockPricesTrend"
          onClose={this.toggleChart3Modal}
          seriesTitle={this.seriesTitle1}
          config={this.config3}
        />
      </>
    );
  }
}

const mapStateToProps = ( { user, settings } ) => {
  const { locale } = settings;
  return { user, locale };
};
const mapDispatchToProps = { toggleLoading: SettingsActions.toggleLoading };

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)( injectIntl( CompanyChart ) );
