import React from 'react';
import { IconButton, Tooltip } from '@material-ui/core';
import { Link } from 'react-router-dom';
import FeatherIcon from '../FeatherIcon';
import { injectIntl } from 'react-intl';

export default injectIntl(({ intl,label="editUserLicense", onClick, isEdit, linkTo, className }) =>
  linkTo ? (
    <Link to={linkTo} className="text-decoration-none">
       <Tooltip title={label ?intl.formatMessage({id:label}) :""} >
      <IconButton
           className=" square-icon-button mx-1"
      >
        <FeatherIcon icon="edit2" size="20px" color="primary2.main" />
        {/* {label ? <IntlMessages id={label}/> : null} */}
      </IconButton>
      </Tooltip>
    </Link>
  )
    : (
      <Tooltip title={label ?intl.formatMessage({id:label}) :""} >
      <IconButton
        className=" square-icon-button mx-1"
        onClick={isEdit ? onClick : () => onClick()}
      >
        <FeatherIcon icon={isEdit ?"edit2":"plus"} size="20px" color="primary2.main" />
        {/* {label ? <IntlMessages id={label}/> : null} */}
      </IconButton>
      </Tooltip>
    ))
