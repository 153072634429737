import { useQuery } from 'react-query';
import { useSelector } from 'react-redux';
import SessionService from 'services/Session';
import { getSessionFields } from '../utils/get-session-fields';

/**
 *
 * Obtiene una sesión por ID.
 *
 * @param {number} sessionId - El ID de la sesión a obtener.
 * @returns {Promise<LivemaxSession>} - Una promesa que se resuelve con la sesión.
 */
const getSessionById = async ( sessionId ) => {
  const res = await SessionService.getSession( sessionId );
  if ( res.ok ) {
    return res.data;
  }
  throw res.errors;
};

export const useActiveSessionQuery = ( queryProps = {} ) => {
  // eslint-disable-next-line max-len
  const activeSessionId = useSelector(
    ( { user } ) => user && user.activeSession && user.activeSession.id,
  );
  // eslint-disable-next-line max-len
  const activeSessionRedux = useSelector(
    ( { user } ) => user && user.activeSession && user.activeSession,
  );

  const sessionQuery = useQuery(
    ['v1', 'session', activeSessionId],
    () => getSessionById( activeSessionId ),
    {
      initialData: activeSessionRedux,
      enabled: !!activeSessionId,
      refetchInterval: 1000 * 60 * 3,
      staleTime: 1000 * 60,
      ...queryProps,
    },
  );

  const newActiveSession = sessionQuery.data && {
    ...sessionQuery.data,
    ...getSessionFields( sessionQuery.data ),
  };

  return {
    ...sessionQuery,
    activeSessionId,
    data: newActiveSession,
    activeSession: newActiveSession,
  };
};

/**
 * @typedef {Object} Supervisor
 * @property {string} id - The unique identifier of the supervisor.
 * @property {string} name - The first name of the supervisor.
 * @property {string} lastName - The last name of the supervisor.
 */

/**
 * @typedef {Object} Entity
 * @property {string} avatarPath - The URL path to the entity's avatar image.
 * @property {number} usedLicenses - The number of used licenses by the entity.
 * @property {number} unusedLicenses - The number of unused licenses by the entity.
 * @property {string} id - The unique identifier of the entity.
 * @property {string} name - The name of the entity.
 * @property {Array} sessions - The sessions associated with the entity.
 * @property {string} country - The country code of the entity.
 * @property {string} email - The contact email of the entity.
 * @property {string} contactName - The name of the contact person.
 * @property {string} contactEmail - The email of the contact person.
 * @property {string} contactDescription - The description of the contact.
 * @property {string} status - The status of the entity.
 * @property {number} availableLicenses - The number of available licenses.
 * @property {number} minLicensesAvailable - The minimum number of available licenses.
 * @property {string} avatar - The avatar image filename.
 * @property {Array} purchaseLicenses - The purchased licenses by the entity.
 * @property {boolean} active - Indicates if the entity is active.
 */

/**
 * @typedef {Object} Stock
 * @property {number} stock - The stock quantity.
 * @property {number} value - The value of the stock.
 */

/**
 * @typedef {Object} WorkGroup
 * @property {Stock} stock - The stock information of the work group.
 * @property {number} currentRoundTotalIPlusDCost - The total I+D cost for the current round.
 * @property {number} balance - The balance of the work group.
 * @property {number} currentRoundProductionCost - The production cost for the current round.
 * @property {string} id - The unique identifier of the work group.
 * @property {string} name - The name of the work group.
 * @property {number} cash - The cash balance of the work group.
 * @property {Array} licenses - The licenses associated with the work group.
 * @property {Array} roundSales - The round sales associated with the work group.
 * @property {Array} commodities - The commodities associated with the work group.
 * @property {Array} studyMarketWorkGroups
 * @property {Array} variableData - The variable data associated with the work group.
 * @property {number} points - The points associated with the work group.
 * @property {number} newStockValue - The new stock value of the work group.
 * @property {number} percentagePoints - The percentage points of the work group.
 * @property {number} position - The position of the work group.
 * @property {boolean} internal - Indicates if the work group is internal.
 * @property {number} marketingBudget - The marketing budget of the work group.
 * @property {boolean} active - Indicates if the work group is active.
 */

/**
 * @typedef {Object} Round
 * @property {number} duration - The duration of the round.
 * @property {string} id - The unique identifier of the round.
 * @property {string} name - The name of the round.
 * @property {string} status - The status of the round.
 * @property {string} typeDuration - The type of duration (e.g., days).
 * @property {string} startDate - The start date of the round (timestamp).
 * @property {string} endDate - The end date of the round (timestamp).
 * @property {Array} workGroupSales - The work group sales associated with the round.
 * @property {Array} productions - The productions associated with the round.
 * @property {Array} reports - The reports associated with the round.
 * @property {Array} studyMarkets - The study markets associated with the round.
 * @property {Array} variableData - The variable data associated with the round.
 * @property {Array} serviceRounds - The service rounds associated with the round.
 * @property {boolean} processed - Indicates if the round is processed.
 * @property {string} processAt - The processing timestamp of the round.
 * @property {boolean} manualClosing - Indicates if the round is manually closed.
 * @property {boolean} inCrisis - Indicates if the round is in crisis.
 */

/**
 * @typedef {Object} Version
 * @property {string} id - The unique identifier of the version.
 * @property {string} version - The version number.
 * @property {boolean} active - Indicates if the version is active.
 */

/**
 * @typedef {Object} Tip
 * @property {string} id - The unique identifier of the tip.
 * @property {string} title - The title of the tip.
 * @property {string} titleEs - The title of the tip in Spanish.
 * @property {string} content - The content of the tip.
 * @property {string} contentEs - The content of the tip in Spanish.
 * @property {boolean} first - Indicates if the tip is the first one.
 * @property {boolean} last - Indicates if the tip is the last one.
 */

/**
 * @typedef {Object} EnterpriseFields
 * @property {Object} information - The information fields of the enterprise.
 * @property {Object} decision - The decision fields of the enterprise.
 */

/**
 * @typedef {Object} CurrentRound
 * @property {string} id - The unique identifier of the current round.
 * @property {string} name - The name of the current round.
 * @property {string} nameD - The nameD of the current round.
 * @property {string} shortName - The short name of the current round.
 * @property {string} largeName - The large name of the current round.
 * @property {string} status - The status of the current round.
 * @property {string} endDate - The end date of the current round (timestamp).
 * @property {string} startDateInMonth - The start date in month format.
 * @property {string} endDateInMonth - The end date in month format.
 * @property {number} number - The number of the current round.
 * @property {boolean} inCrisis - Indicates if the current round is in crisis.
 */

/**
 * @typedef {Object} WorkingDay
 * @property {number} id - The unique identifier of the working day.
 * @property {string} date - The date of the working day (timestamp).
 */

/**
 * @typedef {Object} LivemaxSession
 * @property {Supervisor} currentSupervisor - The current supervisor of the user.
 * @property {Supervisor} supervisor - The supervisor of the user.
 * @property {number} licensesWithUserActive - The number of active licenses with the user.
 * @property {number} licensesAvailable - The number of available licenses.
 * @property {number} usedLicenses - The number of used licenses.
 * @property {number} licensesInWorkGroups - The number of licenses in work groups.
 * @property {string} status - The status of the session.
 * @property {string} id - The unique identifier of the data.
 * @property {string} name - The name of the data.
 * @property {number} numberGroup - The number of groups.
 * @property {number} maxUserGroup - The maximum number of users in a group.
 * @property {Entity} entity - The entity associated with the data.
 * @property {string} startDate - The start date of the data (timestamp).
 * @property {string} endDate - The end date of the data (timestamp).
 * @property {number} licensesTotal - The total number of licenses.
 * @property {number} initialMoneyPerGroup - The initial money per group.
 * @property {string} currency - The currency of the data.
 * @property {number} stockFinalValue - The final value of the stock.
 * @property {number} fixCostPerGroup - The fixed cost per group.
 * @property {number} taxes - The taxes associated with the data.
 * @property {number} maxProductPerGroup - The maximum number of products per group.
 * @property {Array<WorkGroup>} workGroups - The work groups associated with the data.
 * @property {Array<Round>} rounds - The rounds associated with the data.
 * @property {Array} thirdPartyProducts - The third party products associated with the data.
 * @property {Version} version - The version of the data.
 * @property {number} loanTax - The loan tax associated with the data.
 * @property {string} timeZone - The time zone of the data.
 * @property {CurrentRound} currentRound - The current round of the data.
 * @property {Array} zulipData - The Zulip data associated with the data.
 * @property {string} registrationCode - The registration code of the data.
 * @property {Array<WorkingDay>} workingDays - The working days associated with the data.
 * @property {string} creationStatus - The creation status of the data.
 * @property {number} marketingBudget - The marketing budget of the data.
 * @property {boolean} withCertificate - Indicates if the data includes a certificate.
 * @property {boolean} withStrategies - Indicates if the data includes strategies.
 * @property {number} roundsClosePerDay - The number of rounds closed per day.
 * @property {string} simulationSpeed - The simulation speed of the data.
 * @property {string} marketMaturity - The market maturity status of the data.
 * @property {number} roundCardOff - The round card off value.
 * @property {string} distributionType - The distribution type of the data.
 * @property {string} returnToken - The return token of the data.
 * @property {EnterpriseFields} enterpriseFields - The enterprise fields associated with the data.
 * @property {Array} studioFields - The studio fields associated with the data.
 * @property {Tip} tip - The tip associated with the data.
 * @property {string} tipDate - The date of the tip (timestamp).
 * @property {boolean} withTestRound - Indicates if the data includes a test round.
 * @property {boolean} active - Indicates if the data is active.
 */
