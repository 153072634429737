import React from 'react';
import FeatherIcon from 'components/custom/FeatherIcon';
import { toast } from 'react-toastify';
import defaultTheme from 'constants/themes/defaultTheme';
import IntlMessages from './IntlMessages';

export const showError = ( message, { id, translate = false } = {} ) => toast.error(
  <span className="d-flex align-items-center">
    <FeatherIcon
      className="mr-2"
      icon="xCircle"
      size="35px"
      color="error.main"
    />
    {translate ? <IntlMessages id={message} /> : message}
  </span>,
  {
    id,
    icon: false,
    progressStyle: {
      height: '2px',
      backgroundColor: defaultTheme.palette.error.main,
    },
    bodyStyle: { padding: 0 },
    style: { fontSize: '12px', fontWeight: 500 },

    autoClose: 6000,
  },
);
