import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { connect } from 'react-redux';

import Error404 from 'components/theme/Error404';
import Production from 'routes/App/routes/User/Session/General/Production';
import Target from 'routes/App/routes/User/Session/General/Target';
import Simulation from 'routes/App/routes/User/Session/General/Simulation';
import Bundle from 'routes/App/routes/User/Session/General/Bundle';
import Pop from 'routes/App/routes/User/Session/General/Pop';
import SessionInfo from 'routes/App/routes/User/Session/General/SessionInfo';
import PublicMarket from 'routes/App/routes/User/Session/General/PublicMarket';
import MarketReport from 'routes/App/routes/User/Session/General/MarketReport';
import { Container } from '@material-ui/core';
import NewProduct from './Products/New';
import CopyProduct from './Products/Copy';
import Products from './Products/Dashboard';
import Price from './Price';
import Distribution from './Distribution';
import Service from './Service/Dashboard';
import Cem from './Cem';
import Crm from './Crm';
import OneToOneCampaign from './OneToOneCampaign';
import Strategy from './Strategy';
import NewBundle from './Service/NewBundle';

const General = ( { match /* user */ } ) => (
  <Container className="mt-3" maxWidth="lg">
    <Switch>
      <Route
        exact
        path={`${match.url}/products/:id/new`}
        component={NewProduct}
      />
      <Route
        exact
        path={`${match.url}/products/copy`}
        component={CopyProduct}
      />
      <Route exact path={`${match.url}/products`} component={Products} />
      <Route exact path={`${match.url}/price/:id/new`} component={NewProduct} />
      <Route exact path={`${match.url}/price`} component={Price} />
      <Route
        exact
        path={`${match.url}/distribution/:id/new`}
        component={NewProduct}
      />
      <Route
        exact
        path={`${match.url}/distribution`}
        component={Distribution}
      />
      <Route
        exact
        path={`${match.url}/services/new-bundle`}
        component={NewBundle}
      />
      <Route exact path={`${match.url}/services`} component={Service} />
      <Route
        exact
        path={`${match.url}/one-to-one`}
        component={OneToOneCampaign}
      />
      <Route exact path={`${match.url}/strategy`} component={Strategy} />
      <Route exact path={`${match.url}/production`} component={Production} />
      <Route exact path={`${match.url}/pop`} component={Pop} />
      <Route exact path={`${match.url}/bundles`} component={Bundle} />
      <Route exact path={`${match.url}/crm`} component={Crm} />
      {/*   <Route
            path={`${match.url}/service-param/new`}
            component={ServiceParamNew}
          />
          <Route
            path={`${match.url}/service-param`}
            component={ServiceParam}
          /> */}
      <Route exact path={`${match.url}/cem`} component={Cem} />
      <Route exact path={`${match.url}/target`} component={Target} />
      <Route exact path={`${match.url}/simulation`} component={Simulation} />
      <Route exact path={`${match.url}/session-info`} component={SessionInfo} />
      <Route
        exact
        path={`${match.url}/public-market`}
        component={PublicMarket}
      />
      <Route
        exact
        path={`${match.url}/market-report`}
        component={MarketReport}
      />
      <Route exact path={`${match.url}/strategy`} component={Strategy} />
      <Route path={`${match.url}`} render={Error404} />
    </Switch>
  </Container>
);
const mapStateToProps = ( { user } ) => ( { user } );

export default connect( mapStateToProps )( General );
