import { Grid, IconButton } from '@material-ui/core';
import HoverTitle from 'components/custom_v2/HoverTitle';
import React from 'react';
import IntlMessages from 'utils/IntlMessages';
import EraseIcon from 'assets/images/kotler/icons/icon-erase.png';
import { Link } from 'react-router-dom';
import FeatherIcon from '../FeatherIcon';

const KotlerCardHeader = ( {
  title,
  hoverTitleId,
  downloadTo,
  noTranslate,
  titleComplement = '',
  marginBottom = '12px',
  minHeight = '54px',
  onDelete,
  className,
  rightComponent,
} ) => (
  <div
    className={`text-dark ${className || 'px-2'}`}
    style={{ marginBottom }}
  >
    <Grid container wrap="nowrap" justify="space-between">
      <Grid item>
        <HoverTitle id={hoverTitleId}>
          <h4 className="kotler-card-head" style={{ minHeight }}>
            {title && !noTranslate ? <IntlMessages id={title} /> : title}
            {` ${titleComplement}`}
          </h4>
        </HoverTitle>
      </Grid>

      <Grid item>
        {rightComponent || (
          <div>
            {downloadTo && (
              <IconButton
                className="square-icon-button"
                onClick={onDelete}
                component={Link}
                target="_blank"
                color="primary"
                to={{ pathname: downloadTo }}
              >
                <FeatherIcon
                  icon="download"
                  color="primary.main"
                  size="24px"
                />
              </IconButton>
            )}
            {onDelete && (
              <IconButton
                className="square-icon-button"
                onClick={onDelete}
                color="primary"
              >
                <img
                  style={{ float: 'right' }}
                  src={EraseIcon}
                  alt="erase"
                  className=" "
                />
              </IconButton>
            )}
          </div>
        )}
      </Grid>
    </Grid>
  </div>
);

export default KotlerCardHeader;
