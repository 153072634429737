/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable react/jsx-indent */
import React from 'react';
import { connect } from 'react-redux';
import { Form } from 'react-final-form';
import _ from 'lodash';
import arrayMutators from 'final-form-arrays';
import { Row, Col } from 'reactstrap';
import {
  Button,
  Grid,
  Hidden,
  Step,
  StepLabel,
  Stepper,
  Typography,
} from '@material-ui/core';
import { FieldArray } from 'react-final-form-arrays';
import async from 'async';
import { toast } from 'react-toastify';

import CardBox from 'components/custom_v2/CardBox';
import {
  TextField,
  Select,
  RadioButtons,
  Checkboxes,
  Checkbox,
} from 'components/custom/FormElements';
import SettingsActions from 'store/reducers/Settings';
import IntlMessages from 'utils/IntlMessages';
import { injectIntl } from 'react-intl';
import {
  composeValidators,
  greaterThan,
  required,
  lessOrEqualThanValue,
  greaterOrEqualValue,
  greaterOrEqualThan,
  greaterThanZeroDependency,
  greaterThanZeroDependencyNoEmpty,
} from 'config/InputErrors';
import Util from 'utils/Util';
import RoundService from 'services/Round';
import SessionService from 'services/Session';
import AttributeMediaService from 'services/AttributeMedia';
import CommodityService from 'services/Commodity';
import AttributeService from 'services/Attribute';
import AttributeServiceService from 'services/AttributeService';
import CommunicationService from 'services/Communication';
import SessionInfoService from 'services/SessionInfo';
import CampaignDetail from 'routes/App/components/User/Campaign/Detail';
import ConfirmationDialog from 'components/custom_v2/ConfirmationDialog';
import HoverTitle from 'components/custom_v2/HoverTitle';
import './edit.css';
import HeadConfig from 'components/custom/HeadConfig';
import HeaderImage from 'assets/images/kotler/sectionHeaders/campaing.jpeg';
import KotlerSectionHeader from 'components/custom/KoTlerSectionHeader';
import { Link, Prompt } from 'react-router-dom';
import KotlerCardHeader from 'components/custom/KotlerCardHeader';
import MassMediaSectionTable from './MassMediaSectionTable';
import AiReportModal from './aiReportModal';
import TableMedioDescription from './TableMedioDescription';
import { AiGenerateImage } from './AiGenerateImage';

const transformValuesToAiImage = ( {
  contentType,
  product,
  productFeature,
  service,
  serviceFeature,
  bundle,
  bundleFeature,
} = {} ) => ( {
  contentType,
  product,
  productFeature: productFeature && productFeature[0] && productFeature[0].name,

  productFeature2:
    productFeature && productFeature[1] && productFeature[1].name,
  service,
  serviceFeature: serviceFeature && serviceFeature[0] && serviceFeature[0].name,
  serviceFeature2:
    serviceFeature && serviceFeature[1] && serviceFeature[1].name,
  bundle,
  bundleFeature: bundleFeature && bundleFeature[0] && bundleFeature[0].name,
  bundleFeature2: bundleFeature && bundleFeature[1] && bundleFeature[1].name,
} );

let contentTypes = [
  {
    id: 'BRANDING',
    value: 'branding',
  },
  {
    id: 'PRODUCT',
    value: 'productPromotion',
  },
  {
    id: 'SERVICE',
    value: 'servicePromotion',
  },
  {
    id: 'BUNDLE',
    value: 'bundlePromotion',
  },
  {
    id: 'REGISTER_PRODUCT',
    value: 'productRegistration',
  },
];

// TODO: Poner la traduccion
let internetPlans = [
  {
    id: 'social_network_display',
    label: 'socialNetworkDisplay',
    name: 'socialNetworkDisplay',
    bodyList: [
      {
        id: 'EXECUTIVES',
        value: 'executives',
        type: 'segmentation',
      },
      {
        id: 'PROFESSIONAL',
        value: 'Professionals',
        type: 'segmentation',
      },
      {
        id: 'EMPLOYEES',
        value: 'Employees',
        type: 'segmentation',
      },
      {
        id: 'STUDENTS',
        value: 'Students',
        type: 'segmentation',
      },
      {
        id: 'HOME',
        value: 'Home',
        type: 'segmentation',
      },
    ],
  },
  {
    id: 'search',
    name: 'search',
    label: 'searchInternetPlan',
    bodyLabel: 'searchingFor',
    bodyListStudio: [
      {
        id: 'LAPTOPS',
        value: 'laptops',
        disabled: true,
      },
      {
        id: 'SERIES_MOVIES',
        value: 'series&Movies',
        disabled: true,
      },
      {
        id: 'CELL_PHONES',
        value: 'cellPhones',
      },
      {
        id: 'MUSIC',
        value: 'music',
        disabled: true,
      },
      {
        id: 'TABLETS',
        value: 'tablets',
        disabled: true,
      },
      {
        id: 'NEWS',
        value: 'news',
        disabled: true,
      },
    ],
    bodyList: [
      {
        id: 'LAPTOPS',
        value: 'laptops',
        disabled: false,
      },
      {
        id: 'SERIES_MOVIES',
        value: 'series&Movies',
        disabled: false,
      },
      {
        id: 'CELL_PHONES',
        value: 'cellPhones',
      },
      {
        id: 'MUSIC',
        value: 'music',
        disabled: false,
      },
      {
        id: 'TABLETS',
        value: 'tablets',
        disabled: false,
      },
      {
        id: 'NEWS',
        value: 'news',
        disabled: false,
      },
    ],
  },
  {
    id: 'retargeting',
    name: 'retargeting',
    label: 'retargeting',
    bodyLabel: 'retargetPeopleLabel',
    bodyList: [
      {
        id: 'LAPTOPS',
        value: 'laptops',
      },
      {
        id: 'SERIES_MOVIES',
        value: 'series&Movies',
      },

      {
        id: 'CELL_PHONES',
        value: 'cellPhones',
      },
      {
        id: 'MUSIC',
        value: 'music',
      },
      {
        id: 'TABLETS',
        value: 'tablets',
      },

      {
        id: 'NEWS',
        value: 'news',
      },
    ],
  },
  {
    id: 'artificial_intelligence',
    name: 'artificialIntelligence',
    label: 'artificialIntelligence',
    bodyLabel: 'peoplePosting',
    bodyList: [
      {
        id: 'POSITIVE_SENTIMENT',
        value: 'positiveSentiment',
      },
      {
        id: 'NEGATIVE_SENTIMENT',
        value: 'negativeSentiment',
      },
    ],
    bodyListLevel2: [
      {
        id: 'OUR_BRANDS',
        value: 'aboutOurBrand',
      },
      {
        id: 'OTHER_BRANDS',
        value: 'aboutOtherBrands',
      },
    ],
  },
];

const Header = ( { title } ) => (
  <Typography variant="h6" color="textPrimary" className="p-2 ">
    <IntlMessages id={title} />
  </Typography>
);

class EditCampaign extends React.Component {
  state = {
    rounds: [],
    products: [],
    services: [],
    bundles: [],
    productAttributes: [],
    mediaAttributes: [],
    serviceAttributes: [],
    activeStep: 0,
    costIndex: { value: 1 },
    mediaCostLabel: [],
    detailData: {},
    confirmExitModal: false,
    aiModal: false,
    population: -1,
    steps: [
      {
        id: 1,
        label: 'info',
      },
      {
        id: 3,
        label: 'media',
      },
      {
        id: 4,
        label: 'internet',
      },
      {
        id: 5,
        label: 'finalConfirmation',
      },
    ],
  };
  dirtyStep = [];
  dirtyFinal = false;
  entityId = null;
  valuesTable = [
    {
      title: 'TV',
      data: [
        {
          id: 1,
          title1: <IntlMessages id="media.audience.net1" />,
          title2: <IntlMessages id="media.audience.broad" />,
          mReach: 22,
          mExec: 20,
          mProf: 30,
          mEmpl: 40,
          mStudents: 30,
          mHome: 30,
        },
        {
          id: 2,
          title1: <IntlMessages id="media.audience.net2" />,
          title2: <IntlMessages id="media.audience.older" />,
          mReach: 14,
          mExec: 40,
          mProf: 45,
          mEmpl: 30,
          mStudents: 10,
          mHome: 15,
        },
        {
          id: 3,
          title1: <IntlMessages id="media.audience.net3" />,
          title2: <IntlMessages id="media.audience.mid" />,
          mReach: 20,
          mExec: 3,
          mProf: 5,
          mEmpl: 20,
          mStudents: 20,
          mHome: 30,
        },
      ],
      total: {
        title1: (
          <b style={{ textTransform: 'uppercase' }}>
            <IntlMessages id="total" />
          </b>
        ),
        title2: '',
        mReach: 56,
        mExec: 63,
        mProf: 80,
        mEmpl: 90,
        mStudents: 60,
        mHome: 75,
      },
    },
    {
      title: 'RADIO',
      data: [
        {
          id: 1,
          title1: <IntlMessages id="media.audience.r1" />,
          title2: <IntlMessages id="media.audience.younger" />,
          mReach: 20,
          mExec: 10,
          mProf: 15,
          mEmpl: 25,
          mStudents: 45,
          mHome: 20,
        },
        {
          id: 2,
          title1: <IntlMessages id="media.audience.r2" />,
          title2: <IntlMessages id="media.audience.executives" />,
          mReach: 10,
          mExec: 40,
          mProf: 35,
          mEmpl: 20,
          mStudents: 10,
          mHome: 15,
        },
        {
          id: 3,
          title1: <IntlMessages id="media.audience.r3" />,
          title2: <IntlMessages id="media.audience.mid" />,
          mReach: 20,
          mExec: 20,
          mProf: 20,
          mEmpl: 25,
          mStudents: 5,
          mHome: 40,
        },
      ],
      total: {
        title1: (
          <b style={{ textTransform: 'uppercase' }}>
            <IntlMessages id="total" />
          </b>
        ),
        title2: '',
        mReach: 50,
        mExec: 70,
        mProf: 70,
        mEmpl: 70,
        mStudents: 60,
        mHome: 75,
      },
    },
    {
      title: 'PRINT',
      data: [
        {
          id: 1,
          title1: <IntlMessages id="media.audience.pub1" />,
          title2: <IntlMessages id="media.audience.executives" />,
          mReach: 5,
          mExec: 15,
          mProf: 7,
          mEmpl: 3,
          mStudents: 1,
          mHome: 1,
        },
        {
          id: 2,
          title1: <IntlMessages id="media.audience.pub2" />,
          title2: <IntlMessages id="media.audience.older" />,
          mReach: 10,
          mExec: 20,
          mProf: 15,
          mEmpl: 5,
          mStudents: 3,
          mHome: 10,
        },
        {
          id: 3,
          title1: <IntlMessages id="media.audience.pub3" />,
          title2: <IntlMessages id="media.audience.broad" />,
          mReach: 25,
          mExec: 20,
          mProf: 20,
          mEmpl: 20,
          mStudents: 10,
          mHome: 25,
        },
      ],
      total: {
        title1: (
          <b style={{ textTransform: 'uppercase' }}>
            <IntlMessages id="total" />
          </b>
        ),
        title2: '',
        mReach: 40,
        mExec: 55,
        mProf: 42,
        mEmpl: 28,
        mStudents: 14,
        mHome: 36,
      },
    },
    {
      title: 'OUTDOOR',
      data: [
        {
          id: 1,
          title1: '',
          title2: <IntlMessages id="media.audience.allsegment" />,
          mReach: 48,
          mExec: 98,
          mProf: 99,
          mEmpl: 95,
          mStudents: 99,
          mHome: 85,
        },
      ],
    },
    {
      title: 'INTERNET',
      data: [
        {
          id: 1,
          title1: '',
          title2: <IntlMessages id="media.audience.allsegment" />,
          mReach: 45,
          mExec: 90,
          mProf: 85,
          mEmpl: 90,
          mStudents: 100,
          mHome: 50,
        },
      ],
    },
  ];

  componentDidMount() {
    const { toggleAlert, user, match } = this.props;
    if ( !user.activeSession ) {
      return toggleAlert( 'ERROR' );
    }

    this.license = _.find( user.licenses, { session: { id: user.activeSession.id } } );
    if ( !this.license || !this.license.workGroup ) {
      return toggleAlert( 'ERROR' );
    }
    this.activeSession = user.activeSession;

    this.mkmode = user.activeSession.fields;
    this.isEnterprise = user.activeSession.isEnterprise;

    if (
      !this.mkmode.decision.communications.informationStep.defineTarget
        .servicePromotion
    ) {
      contentTypes = _.filter(
        contentTypes,
        o => o.value.indexOf( 'servicePromotion' ) === -1,
      );
    }
    if (
      !this.mkmode.decision.communications.informationStep.defineTarget
        .bundlePromotion
    ) {
      contentTypes = _.filter(
        contentTypes,
        o => o.value.indexOf( 'bundlePromotion' ) === -1,
      );
    }
    if (
      !this.mkmode.decision.communications.informationStep.defineTarget
        .productRegistration
    ) {
      contentTypes = _.filter(
        contentTypes,
        o => o.value.indexOf( 'productRegistration' ) === -1,
      );
    }
    if ( !this.mkmode.decision.communications.internetStep.retargeting ) {
      internetPlans = _.filter(
        internetPlans,
        o => o.name.indexOf( 'retargeting' ) === -1,
      );
    }
    if ( !this.mkmode.decision.communications.internetStep.ia ) {
      internetPlans = _.filter(
        internetPlans,
        o => o.name.indexOf( 'artificialIntelligence' ) === -1,
      );
      internetPlans = _.filter(
        internetPlans,
        o => o.name.indexOf( 'search' ) === -1,
      );
    }
    this.entityId = match.params.id;
    this.getData( this.entityId );
  }

  setDirty( dirty, idStep ) {
    if ( this.dirtyStep[idStep] !== dirty ) {
      this.dirtyStep[idStep] = dirty;
      // eslint-disable-next-line max-len
      this.dirtyFinal = this.dirtyStep.reduce( ( previus, value ) =>
        ( previus === true ? true : value ) );
    }
  }

  toggleConfirmExitModal = () => {
    const { confirmExitModal } = this.state;
    this.setState( { confirmExitModal: !confirmExitModal } );
  };

  toggleAiModal = () => {
    const { aiModal } = this.state;
    this.setState( { aiModal: !aiModal } );
  };

  setConfirmExitModal = () => {
    const { history } = this.props;
    this.canExit = true;
    if ( this.goTo !== '' ) {
      history.push( this.goTo );
    }
  };
  genderFromApiToForm = ( gender, exist ) => {
    if ( gender === 'MALE' || gender === 'FEMALE' ) return [gender];
    if ( !gender && exist ) return ['MALE', 'FEMALE'];
    return [];
  };
  getData = () => {
    const { steps } = this.state;
    const { toggleLoading, toggleAlert, user, intl } = this.props;
    const newSteps = _.cloneDeep( steps );
    this.mkmode = user.activeSession.fields;
    this.isEnterprise = user.activeSession.isEnterprise;
    toggleLoading( true );
    async.parallel(
      {
        entity: this.getEntity,
        costIndex: this.getCostIndex,
        rounds: this.getRounds,
        commodities: this.getCommodities,
        aditionalData: this.getAditionalData,
        productAttributes: this.getProductAttributes,
        serviceAttributes: this.getServiceAttributes,
        mediaAttributes: this.getMediaAttributes,
      },
      ( error, results ) => {
        toggleLoading( false );
        if ( error ) {
          return toggleAlert( error );
        }
        let entity = {};
        if ( this.entityId ) {
          entity = {
            ...results.entity,
            fromRound: results.entity.fromRound
              ? results.entity.fromRound.id
              : '',
            toRound: results.entity.toRound ? results.entity.toRound.id : '',
            mediaAttributes: _.map( results.mediaAttributes, ( item ) => {
              // eslint-disable-next-line max-len
              const selected = _.find( results.entity.monthlyBudgets, { attributeMedia: { id: item.id } } );
              return {
                ...item,
                selected: !!selected,
                intensity:
                  selected && selected.intensity
                    ? selected.intensity.toString()
                    : null,
              };
            } ),
            internetPlans: _.cloneDeep( internetPlans ),
          };
          if ( entity.socialNetworkDisplay ) {
            entity.internetPlans[0] = {
              ...entity.internetPlans[0],
              gender: this.genderFromApiToForm(
                entity.socialNetworkDisplaygender,
                !!entity.socialNetworkDisplay.monthlyInvestment,
              ),
              ageFrom: entity.socialNetworkDisplay.ageFrom,
              ageTo: entity.socialNetworkDisplay.ageTo,
              monthlyInvestment: entity.socialNetworkDisplay.monthlyInvestment,
              body: [
                ..._.map( entity.socialNetworkDisplay.segmentation, item => ( {
                  type: 'segmentation',
                  id: item,
                } ) ),
              ],
            };
          }
          if ( entity.search ) {
            entity.internetPlans[1] = {
              ...entity.internetPlans[1],
              gender: this.genderFromApiToForm(
                entity.search.gender,
                !!entity.search.monthlyInvestment,
              ),
              ageFrom: entity.search.ageFrom,
              ageTo: entity.search.ageTo,
              monthlyInvestment: entity.search.monthlyInvestment,
              body: _.map( entity.search.searchingFor, body => ( { id: body } ) ),
            };
          }

          if (
            entity.retargeting
            && this.mkmode.decision.communications.internetStep.retargeting
          ) {
            entity.internetPlans[2] = {
              ...entity.internetPlans[2],
              gender: this.genderFromApiToForm(
                entity.retargeting.gender,
                !!entity.retargeting.monthlyInvestment,
              ),
              ageFrom: entity.retargeting.ageFrom,
              ageTo: entity.retargeting.ageTo,
              monthlyInvestment: entity.retargeting.monthlyInvestment,
              body: _.map( entity.retargeting.retargetLookingFor, body => ( { id: body } ) ),
            };
          }
          if (
            entity.artificialIntelligence
            && this.mkmode.decision.communications.internetStep.ia
          ) {
            entity.internetPlans[3] = {
              ...entity.internetPlans[3],
              gender: this.genderFromApiToForm(
                entity.artificialIntelligence.gender,
                !!entity.artificialIntelligence.monthlyInvestment,
              ),
              ageFrom: entity.artificialIntelligence.ageFrom,
              ageTo: entity.artificialIntelligence.ageTo,
              monthlyInvestment:
                entity.artificialIntelligence.monthlyInvestment,
              body: entity.artificialIntelligence.peoplePosting,
              body2: entity.artificialIntelligence.aboutBrand,
            };
          }
          if ( entity.contentType === 'PRODUCT' ) {
            entity.product = entity.product ? entity.product.id : '';
            entity.productFeature = [];
            if ( results.entity.productFeature ) {
              entity.productFeature.push( { id: results.entity.productFeature.id } );
            }
            if ( entity.productFeature2 ) {
              entity.productFeature.push( { id: results.entity.productFeature2.id } );
            }
          }
          if ( entity.contentType === 'SERVICE' ) {
            entity.service = entity.service ? entity.service.id : '';
            entity.serviceFeature = [];
            if ( results.entity.serviceFeature ) {
              entity.serviceFeature.push( { id: results.entity.serviceFeature.id } );
            }
            if ( entity.serviceFeature2 ) {
              entity.serviceFeature2.push( { id: results.entity.serviceFeature2.id } );
            }
          }
          if ( entity.contentType === 'BUNDLE' ) {
            entity.bundle = entity.bundle ? entity.bundle.id : '';
          }

          if (
            entity.contentType === 'PRODUCT'
            || entity.contentType === 'SERVICE'
            || entity.contentType === 'BUNDLE'
          ) {
            if ( newSteps[1].id !== 2 ) {
              newSteps.splice( 1, 0, {
                id: 2,
                label: 'promotion',
              } );
            }
          }
        }

        this.setState(
          {
            ...results,
            entity,
            steps: newSteps,
            products:
              user.activeSession.distributionType === 'studio'
                ? _.filter( results.commodities, {
                  owner: 'PRODUCT',
                  category: 'Cellular',
                } )
                : _.filter( results.commodities, { owner: 'PRODUCT' } ),
            services: _.filter( results.commodities, { owner: 'SERVICE' } ),
            bundles: _.filter( results.commodities, { owner: 'BUNDLE' } ),
            rounds: _.map( results.rounds, round => ( {
              id: round.id,
              name: `${intl.formatMessage( { id: round.largeName.split( ' ' )[0].toLowerCase() } )} ${round.largeName.split( ' ' )[1]}`,
            } ) ),
          },
          () =>
            this.setState( { detailData: this.entityId ? this.loadDataDetail( entity ) : {} } ),
        );
      },
    );
  };

  getEntity = ( cb ) => {
    if ( !this.entityId ) return cb();
    // eslint-disable-next-line max-len
    CommunicationService.getCampaign( this.activeSession.id, this.entityId, { workgroup: this.license.workGroup.id } ).then( ( response ) => {
      if ( !response.ok ) return cb( response.errors );
      return cb( null, response.data );
    } );
  };

  getCostIndex = ( cb ) => {
    // eslint-disable-next-line max-len
    CommunicationService.getCostIndex( this.activeSession.id, { workgroup: this.license.workGroup.id } ).then( ( response ) => {
      if ( !response.ok ) return cb( response.errors );
      return cb( null, response.data );
    } );
  };

  getRounds = ( cb ) => {
    RoundService.getRoundsBySession( this.activeSession.id, {
      limit: -1,
      'filters[noStatus]': 'CLOSED',
    } ).then( ( response ) => {
      if ( !response.ok ) return cb( response.errors );
      return cb( null, response.data.data );
    } );
  };

  getCommodities = ( cb ) => {
    CommodityService.getCommodities( this.activeSession.id, {
      workgroup: this.license.workGroup.id,
      limit: -1,
    } ).then( ( response ) => {
      if ( !response.ok ) return cb( response.errors );
      return cb( null, response.data.data );
    } );
  };

  getAditionalData = ( cb ) => {
    SessionInfoService.getAditionalData( this.activeSession.id, {
      workgroup: this.license.workGroup.id,
      type: 'people_listening',
    } ).then( ( response ) => {
      if ( !response.ok ) return cb( response.errors );
      cb( null, response.data.data );
    } );
  };

  getProductAttributes = ( cb ) => {
    AttributeService.getAttributes( {
      'filters[session]': this.activeSession.id,
      limit: -1,
    } ).then( ( response ) => {
      if ( !response.ok ) return cb( response.errors );
      cb( null, response.data.data );
    } );
  };

  getServiceAttributes = ( cb ) => {
    AttributeServiceService.getAttributes( {
      'filters[session]': this.activeSession.id,
      limit: -1,
    } ).then( ( response ) => {
      if ( !response.ok ) return cb( response.errors );
      cb( null, response.data.data );
    } );
  };

  getMediaAttributes = ( cb ) => {
    AttributeMediaService.getAttributes( {
      'filters[session]': this.activeSession.id,
      'sortby[orderAttribute]': 'asc',
      limit: -1,
    } ).then( ( response ) => {
      if ( !response.ok ) return cb( response.errors );
      cb( null, response.data.data );
    } );
  };

  submitForm = ( data ) => {
    const { steps, activeStep } = this.state;
    const { intl } = this.props;

    if ( steps[activeStep].id < 5 ) return;
    const { toggleLoading, toggleAlert, history } = this.props;

    const dataToSend = {
      workgroup: this.license.workGroup.id,
      name: data.name
        .normalize( 'NFD' )
        .replace( /[\u0300-\u036f]/g, '' )
        .replace( /[^a-zA-Z0-9 ]/g, '' ),
      contentType: data.contentType,
      fromRound: data.fromRound,
      toRound: data.toRound,
      monthlyBudgets: [],
      promotePurchasing: !!data.promotePurchasing,
      socialNetworkDisplay: {},
      search: {},
      retargeting: {},
      artificialIntelligence: {},
    };

    if ( !this.mkmode.decision.communications.mediaStep ) {
      dataToSend.monthlyBudgetInvestment = data.monthlyBudgetInvestment;
    }

    if ( data.contentType === 'PRODUCT' ) {
      dataToSend.product = data.product;
      if ( data.productFeature ) {
        if ( data.productFeature.length === 1 ) {
          dataToSend.productFeature = data.productFeature[0].id;
        }
        if ( data.productFeature.length === 2 ) {
          dataToSend.productFeature = data.productFeature[0].id;
          dataToSend.productFeature2 = data.productFeature[1].id;
        }
      }
    }
    if ( data.contentType === 'SERVICE' ) {
      dataToSend.service = data.service;
      if ( data.serviceFeature ) {
        if ( data.serviceFeature.length === 1 ) {
          dataToSend.serviceFeature = data.serviceFeature[0].id;
        }
        if ( data.serviceFeature.length === 2 ) {
          dataToSend.serviceFeature = data.serviceFeature[0].id;
          dataToSend.serviceFeature2 = data.serviceFeature[1].id;
        }
      }
    }
    if ( data.contentType === 'BUNDLE' ) {
      dataToSend.bundle = data.bundle;
    }

    if ( data.mediaAttributes ) {
      const selected = _.filter( data.mediaAttributes, 'intensity' );
      dataToSend.monthlyBudgets = _.map( selected, item => ( {
        attributeMedia: item.id,
        intensity: item.intensity,
      } ) );
    }

    _.map( data.internetPlans, ( plan ) => {
      if ( plan.id === 'social_network_display' ) {
        const gender = plan.gender && plan.gender.length === 1 ? plan.gender[0] : null;
        dataToSend.socialNetworkDisplay = {
          gender,
          ageFrom: plan.ageFrom,
          ageTo: plan.ageTo,
          monthlyInvestment: plan.monthlyInvestment,
          segmentation: _.chain( plan.body )
            .filter( { type: 'segmentation' } )
            .map( 'id' )
            .value(),
        };
      }
      if ( plan.id === 'search' ) {
        const gender = plan.gender && plan.gender.length === 1 ? plan.gender[0] : null;
        dataToSend.search = {
          gender,
          ageFrom: plan.ageFrom,
          ageTo: plan.ageTo,
          searchingFor: _.map( plan.body, 'id' ),
          monthlyInvestment: plan.monthlyInvestment,
        };
      }
      if ( plan.id === 'retargeting' ) {
        const gender = plan.gender && plan.gender.length === 1 ? plan.gender[0] : null;
        dataToSend.retargeting = {
          gender,
          ageFrom: plan.ageFrom,
          ageTo: plan.ageTo,
          retargetLookingFor: _.map( plan.body, 'id' ),
          monthlyInvestment: plan.monthlyInvestment,
        };
      }
      if ( plan.id === 'artificial_intelligence' ) {
        const gender = plan.gender && plan.gender.length === 1 ? plan.gender[0] : null;
        dataToSend.artificialIntelligence = {
          gender,
          ageFrom: plan.ageFrom,
          ageTo: plan.ageTo,
          peoplePosting: plan.body,
          aboutBrand: plan.body2,
          monthlyInvestment: plan.monthlyInvestment,
        };
      }
    } );

    toggleLoading( true );
    CommunicationService.saveCampaign( this.activeSession.id, dataToSend ).then(
      ( response ) => {
        toggleLoading( false );
        if ( !response.ok ) {
          const messageErr = response.errors
            === 'ERROR_MESSAGE_THIS_WORKGROUP_DOES_NOT_HAVE_ENOUGH_MARKETING_BUDGET'
            ? intl.formatMessage( {
              id:
                    'ERROR_MESSAGE_THIS_WORKGROUP_DOES_NOT_HAVE_ENOUGH_MARKETING_BUDGET',
            } )
            : response.errors;
          return toggleAlert( messageErr );
        }
        this.canExit = true;
        toggleAlert( 'dataSaved', 'info' );
        // eslint-disable-next-line max-len
        if (
          this.license
          && this.license.session
          && this.license.session.currentRound
          && this.activeSession.currentRound.id !== data.fromRound
        ) {
          toast.warning(
            <div>
              <div style={{ textAlign: 'center' }}>
                <h1 style={{ color: '#04A89E' }}>
                  <IntlMessages id="sweetAlerts.warning" />
                </h1>
              </div>
              <div style={{ marginTop: '30px' }}>
                <p>
                  <IntlMessages id="campingWontStartUntil" />
                </p>
              </div>
            </div>,
            {
              position: 'top-left',
              autoClose: false,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
            },
          );
        }
        history.push( '/sessions/general/crm' );
      },
    );
  };

  loadDataDetail = ( dataDetail ) => {
    const {
      rounds,
      products,
      services,
      bundles,
      productAttributes,
      serviceAttributes,
    } = this.state;
    const data = _.cloneDeep( dataDetail );
    if ( data.fromRound ) {
      const fromRound = _.find( rounds, { id: data.fromRound } );
      if ( fromRound ) data.fromRound = fromRound.name;
    }
    if ( data.toRound ) {
      const toRound = _.find( rounds, { id: data.toRound } );
      if ( toRound ) data.toRound = toRound.name;
    }
    if ( data.product ) {
      const product = _.find( products, { id: data.product } );
      if ( product ) data.product = product.name;
    }
    if ( data.service ) {
      const service = _.find( services, { id: data.service } );
      if ( service ) data.service = service.name;
    }
    if ( data.bundle ) {
      const bundle = _.find( bundles, { id: data.bundle } );
      if ( bundle ) data.bundle = bundle.name;
    }
    data.productFeature = _.map( data.productFeature, ( item ) => {
      const productAttribute = _.find( productAttributes, { id: item.id } );
      if ( productAttribute ) return { id: productAttribute.name };
      return item.id;
    } );
    data.serviceFeature = _.map( data.serviceFeature, ( item ) => {
      const serviceAttribute = _.find( serviceAttributes, { id: item.id } );
      if ( serviceAttribute ) return { id: serviceAttribute.name };
      return item.id;
    } );

    return data;
  };

  handleNext = ( data ) => {
    const { activeStep } = this.state;
    this.setState( {
      activeStep: activeStep + 1,
      detailData: this.loadDataDetail( data ),
    } );
  };

  handleBack = ( data ) => {
    const { activeStep } = this.state;
    this.setState( {
      activeStep: activeStep - 1,
      detailData: this.loadDataDetail( data ),
    } );
  };

  computePopulation = ( data ) => {
    const { toggleLoading } = this.props;

    toggleLoading( true );

    let dataToSend = {};
    _.map( data.internetPlans, ( plan ) => {
      const gender = plan.gender && plan.gender.length === 1 ? plan.gender[0].id : null;
      if ( plan.id === 'social_network_display' ) {
        const segments = _.chain( plan.body )
          .filter( { type: 'segmentation' } )
          .map( 'id' )
          .value();
        if ( _.indexOf( segments, 'EMPLOYEES' ) !== -1 ) {
          segments[_.indexOf( segments, 'EMPLOYEES' )] = 'Employee';
        }
        if ( _.indexOf( segments, 'STUDENTS' ) !== -1 ) {
          segments[_.indexOf( segments, 'STUDENTS' )] = 'Student';
        }
        if ( _.indexOf( segments, 'EXECUTIVES' ) !== -1 ) {
          segments[_.indexOf( segments, 'EXECUTIVES' )] = 'Executive';
        }
        if ( _.indexOf( segments, 'HOME' ) !== -1 ) {
          segments[_.indexOf( segments, 'HOME' )] = 'Home';
        }
        if ( _.indexOf( segments, 'PROFESSIONAL' ) !== -1 ) {
          segments[_.indexOf( segments, 'PROFESSIONAL' )] = 'Professional';
        }
        dataToSend = {
          'filters[gender]': gender,
          'filters[ageFrom]': plan.ageFrom,
          'filters[ageTo]': plan.ageTo,
          'filters[inSegments]': segments,
        };
      }
    } );
    // dataToSend.limit = -1;
    SessionService.getPopulationSample( this.activeSession.id, dataToSend ).then(
      ( response ) => {
        toggleLoading( false );
        if ( response.ok ) {
          this.setState( { population: response.data.total } );
        }
      },
    );
  };

  getStepButtons = ( form ) => {
    const { activeStep, steps } = this.state;
    const lastStep = activeStep >= steps.length - 1;
    return (
      <div className="text-center d-flex align-items-center justify-content-end w-100 h-100">
        <Button
          variant="text"
          className="kotler-button kotler-button-danger "
          component={Link}
          to="/sessions/general/crm"
          type="submit"
        >
          <IntlMessages id="cancelCreation" />
        </Button>

        <Button
          variant="outlined"
          disabled={activeStep <= 0}
          className="kotler-button "
          color="primary"
          type="submit"
          onClick={() => {
            if ( form.getState().valid ) this.handleBack( form.getState().values );
          }}
        >
          <IntlMessages id="back" />
        </Button>
        <Button
          variant={lastStep ? 'contained' : 'contained'}
          color="primary"
          className="kotler-button kotler-button-primary mx-2"
          onClick={() => {
            if ( !form.getState().valid || lastStep ) {
              form.submit();
            } else if ( form.getState().valid ) this.handleNext( form.getState().values );
          }}
        >
          {lastStep ? <IntlMessages id="save" /> : <IntlMessages id="next" />}
        </Button>
      </div>
    );
  };

  getInternetCost = ( obj, objCost ) => {
    switch ( obj.id ) {
    case 'social_network_display':
      return objCost.socialNetworkCost;
    case 'search':
      return objCost.searchCost;
    case 'retargeting':
      return objCost.retargetingCost;
    case 'artificial_intelligence':
      return objCost.artIntelligenceCost;
    default:
      return 1;
    }
  };

  getMediaCost = ( defaultValue, objMediaCost ) => {
    switch ( defaultValue ) {
    case '1':
      return objMediaCost.cost;
    case '2':
      return objMediaCost.cost2;
    case '3':
      return objMediaCost.cost3;
    case '4':
      return objMediaCost.cost4;
    case '5':
      return objMediaCost.cost5;
    default:
      return 0;
    }
  };
  calcMonthyInvestment( monthlyInvestment, internetPlan, costIndex ) {
    // eslint-disable-next-line max-len
    const result = Math.round(
      ( ( monthlyInvestment / this.getInternetCost( internetPlan, costIndex ) )
        * 10 )
        / 10,
    );
    return result;
  }
  render() {
    const {
      entity,
      rounds,
      products,
      services,
      bundles,
      activeStep,
      steps,
      confirmExitModal,
      productAttributes,
      mediaAttributes,
      costIndex,
      mediaCostLabel,
      detailData,
      population,
      aiModal,
      aditionalData,
    } = this.state;
    const { valuesTable } = this;
    const { user, intl } = this.props;
    this.mkmode = user.activeSession.fields;
    this.isEnterprise = user.activeSession.isEnterprise;
    return (
      <>
        <HeadConfig
          breadcrumbsLinks={[
            {
              url: '/',
              name: 'HomeLink',
            },
            {
              url: '/sessions/general/crm',
              name: 'DecisionDashboard-comunications',
            },
            { name: 'newCampaign' },
          ]}
        />
        <KotlerSectionHeader
          backTo="/sessions/general/crm"
          title="crm"
          image={HeaderImage}
        />
        <Prompt
          when={!this.canExit}
          message={( location ) => {
            const { canExit, dirtyFinal } = this;
            if ( dirtyFinal && !canExit ) {
              this.goTo = location.pathname;
              this.toggleConfirmExitModal();
              return false;
            }
            return true;
          }}
        />

        <Form
          initialValues={{
            mediaAttributes,
            internetPlans,
            ...entity,
          }}
          validate={( values ) => {
            const errors = {};
            const mediaAttributesErrors = [];
            if ( values.mediaAttributes && values.mediaAttributes.length ) {
              _.map( values.mediaAttributes, ( item, index ) => {
                if ( !item.selected ) return;

                if ( !item.intensity ) {
                  mediaAttributesErrors[index] = {};
                  mediaAttributesErrors[index].intensity = 'fieldError.required';
                }
              } );
              if ( mediaAttributesErrors.length ) {
                errors.mediaAttributes = mediaAttributesErrors;
              }
            }
            if ( steps[activeStep].id === 4 ) {
              const internetPlansErrors = [];
              // const MAX_PEOPLE_REACHED = 50000000;
              if ( values.internetPlans && values.internetPlans.length ) {
                _.map( values.internetPlans, ( item, index ) => {
                  if (
                    item.id === 'social_network_display'
                    && !item.monthlyInvestment
                    && ( item.ageFrom
                      || item.ageTo
                      || ( item.gender && item.gender.length > 0 )
                      || ( item.body && item.body.length ) )
                  ) {
                    internetPlansErrors[index] = {};
                    internetPlansErrors[index].monthlyInvestment = 'fieldError.required';
                  }
                  if (
                    item.id !== 'social_network_display'
                    && !item.monthlyInvestment
                    && ( item.ageFrom
                      || item.ageTo
                      || ( item.gender && item.gender.length > 0 )
                      || ( item.body && item.body.length ) )
                  ) {
                    internetPlansErrors[index] = {};
                    internetPlansErrors[index].monthlyInvestment = 'fieldError.required';
                  }
                  /* if ( item.monthlyInvestment > MAX_PEOPLE_REACHED ) {
                    internetPlansErrors[index] = {};
                    internetPlansErrors[index].monthlyInvestment = 'fieldError.lessOrEqualThan50M';
                  }
                  */

                  if (
                    item.id === 'artificial_intelligence'
                    && ( item.body && item.body.length && !item.body2 )
                  ) {
                    internetPlansErrors[index] = {};
                    internetPlansErrors[index].body2 = 'fieldError.required';
                  }
                } );
                if ( internetPlansErrors.length ) {
                  errors.internetPlans = internetPlansErrors;
                }
              }
            }
            return errors;
          }}
          onSubmit={this.submitForm}
          mutators={{ ...arrayMutators }}
          render={( { handleSubmit, form, values } ) => (
            <form onSubmit={handleSubmit}>
              <Grid
                container
                className="kotler-stepper-container  flex-column-reverse  flex-md-row my-4"
              >
                <Grid item xs={12} md={7}>
                  <Stepper
                    activeStep={activeStep}
                    className="kotler-stepper  bg-transparent"
                  >
                    {steps.map( ( step, index ) => (
                      <Step key={step.id}>
                        <StepLabel
                          className={`${index === activeStep ? 'active' : ''}`}
                          StepIconProps={{ completed: false }}
                        >
                          <IntlMessages id={step.label} />
                        </StepLabel>
                      </Step>
                    ) )}
                  </Stepper>
                </Grid>
                <Grid item xs={12} md={5}>
                  {this.getStepButtons( form )}
                </Grid>
              </Grid>
              {this.setDirty( form.getState().dirty, steps[activeStep].id )}
              {steps[activeStep].id === 1 && (
                <>
                  <Grid
                    container
                    className="definition-view"
                    justify="space-between"
                  >
                    <Grid item xs={12} sm={7} md={6}>
                      <CardBox
                        styleName="h-100"
                        header={(
                          <KotlerCardHeader
                            title="crm"
                            minHeight="0px"
                            onDelete={() => {
                              form.change( 'name', '' );
                              form.change( 'fromRound', null );
                              form.change( 'toRound', null );
                            }}
                          />
                        )}
                      >
                        <Grid container spacing={1}>
                          <Grid item xs={12}>
                            <HoverTitle
                              id="campaignNameInternalIdentificationPurposes"
                              placement="right"
                            >
                              <div>
                                <TextField
                                  adornRequired
                                  field="name"
                                  label="campaignName"
                                  marginContainer={false}
                                  validate={required}
                                  inputProps={{ maxLength: 255 }}
                                />
                              </div>
                            </HoverTitle>
                          </Grid>

                          <Grid item xs={12}>
                            <p className="kotler-text-field-footer pt-0 text-upper">
                              <IntlMessages id="campaignInfo" />
.
                              {' '}
                              <IntlMessages
                                id="maxCharacters"
                                values={{ characters: '255' }}
                              />
                            </p>
                          </Grid>

                          <Grid item xs={12} sm={6}>
                            <HoverTitle
                              id="fromWhenWillCampaignTakePlace"
                              placement="top"
                            >
                              <div>
                                <Select
                                  field="fromRound"
                                  label="startDate"
                                  validate={required}
                                  translateOptions={false}
                                  options={rounds}
                                />
                              </div>
                            </HoverTitle>
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <HoverTitle
                              id="howLongCampaignWillRun"
                              placement="top"
                            >
                              <div>
                                <Select
                                  field="toRound"
                                  label="endDate"
                                  translateOptions={false}
                                  validate={composeValidators(
                                    required,
                                    greaterOrEqualThan( 'fromRound' ),
                                  )}
                                  translateValues={{ label: <IntlMessages id="startDate" /> }}
                                  options={rounds}
                                />
                              </div>
                            </HoverTitle>
                          </Grid>
                        </Grid>
                      </CardBox>
                    </Grid>
                    <Grid item xs={12} sm={5} md={6}>
                      <CardBox
                        styleName="h-100"
                        header={<Header title="campaignMainObjective" />}
                      >
                        <div className="ml-2">
                          <RadioButtons
                            field="contentType"
                            validate={required}
                            options={contentTypes.map( ( content ) => {
                              let disabled = false;
                              if ( content.id === 'PRODUCT' ) {
                                disabled = !products || !products.length;
                              } else if ( content.id === 'SERVICE' ) {
                                disabled = !services || !services.length;
                              } else if ( content.id === 'BUNDLE' ) {
                                disabled = !bundles || !bundles.length;
                              }
                              return {
                                ...content,
                                disabled,
                              };
                            } )}
                            onChange={( value ) => {
                              const newSteps = _.cloneDeep( steps );
                              if (
                                value === 'PRODUCT'
                                || value === 'SERVICE'
                                || value === 'BUNDLE'
                              ) {
                                if ( newSteps[1].id !== 2 ) {
                                  newSteps.splice( 1, 0, {
                                    id: 2,
                                    label: 'promotion',
                                  } );
                                  this.setState( { steps: newSteps } );
                                }
                              } else if ( newSteps[1].id === 2 ) {
                                newSteps.splice( 1, 1 );
                                this.setState( { steps: newSteps } );
                              }
                              form.change( 'product', null );
                              form.change( 'productFeature', null );
                              form.change( 'service', null );
                              form.change( 'serviceFeature', null );
                              form.change( 'bundle', null );
                            }}
                            hovers={[
                              {
                                id:
                                  "increasesConsumers'OverallInterestBrand'sProductsServices",
                              },
                              { id: 'campaignsAimedPromotingSpecificProduct' },
                              { id: 'campaignsAimedPromotingSpecificService' },
                              { id: 'campaignsAimedPromotingSpecificBundle' },
                              {
                                id:
                                  'campaignsEncourageThosePurchasedThroughRetailChannel',
                              },
                            ]}
                          />
                        </div>
                      </CardBox>
                    </Grid>
                  </Grid>
                </>
              )}
              {steps[activeStep].id === 2 && (
                <Grid container style={{ rowGap: '16px' }}>
                  {values.contentType === 'PRODUCT' && (
                    <>
                      <Grid item xs={12}>
                        <Header title="productPromotionCampaign" />
                      </Grid>

                      <Grid item xs={12}>
                        <RadioButtons
                          adornRequired
                          label="selectProductPromote"
                          field="product"
                          translate={false}
                          marginContainer={false}
                          valueKey="name"
                          options={products}
                          validate={required}
                        />
                      </Grid>

                      {this.mkmode.decision.communications.promotionStep
                        .prodAttr && (
                        <Grid item xs={12}>
                          <HoverTitle id="possibleAttributesProductToPromotedCommunication">
                            <div>
                              <Checkboxes
                                label="defineAttributePromote"
                                marginContainer={false}
                                field="productFeature"
                                /*     labelClassName="col-md-12 mr-0"
                                      labelContainerClass="row" */
                                validate={( value ) => {
                                  if ( value && value.length > 2 ) {
                                    return 'fieldError.maxProductFeature';
                                  }
                                }}
                                valueKey="name"
                                options={[
                                  ...productAttributes,
                                  // {
                                  //   id: 'PriceIdInvaliBd',
                                  //   name: 'Price',
                                  //   label: 'Price',
                                  // },
                                ]}
                              />
                            </div>
                          </HoverTitle>
                        </Grid>
                      )}
                      <Grid item xs={12}>
                        <HoverTitle id="generatesCallToActionCommunication">
                          <div>
                            <Checkbox
                              marginContainer={false}
                              field="promotePurchasing"
                              label="promotePurchasingDirectChannel"
                            />
                          </div>
                        </HoverTitle>
                      </Grid>
                    </>
                  )}

                  {values.contentType === 'SERVICE' && (
                    <>
                      <Grid item xs={12}>
                        <Header title="servicePromotionCampaign" />
                      </Grid>
                      <Grid item xs={12}>
                        <RadioButtons
                          adornRequired
                          label="selectServicePromote"
                          field="service"
                          translate={false}
                          marginContainer={false}
                          valueKey="name"
                          options={services}
                          validate={required}
                        />
                      </Grid>

                      <Grid item xs={12}>
                        <HoverTitle id="generatesCallToActionCommunication">
                          <div>
                            <Checkbox
                              marginContainer={false}
                              field="promotePurchasing"
                              label="promotePurchasingDirectChannel"
                            />
                          </div>
                        </HoverTitle>
                      </Grid>
                    </>
                  )}

                  {values.contentType === 'BUNDLE' && (
                    <>
                      <Grid item xs={12}>
                        <Header title="bundlePromotionCampaign" />
                      </Grid>
                      <Grid item xs={12}>
                        <div>
                          <RadioButtons
                            adornRequired
                            label="selectBundlePromote"
                            field="bundle"
                            translate={false}
                            marginContainer={false}
                            valueKey="name"
                            options={bundles}
                            validate={required}
                          />
                        </div>
                      </Grid>
                      <Grid item xs={12}>
                        <HoverTitle id="generatesCallToActionCommunication">
                          <div>
                            <Checkbox
                              marginContainer={false}
                              field="promotePurchasing"
                              label="promotePurchasingDirectChannel"
                            />
                          </div>
                        </HoverTitle>
                      </Grid>
                    </>
                  )}
                  {this.getStepButtons( form )}
                </Grid>
              )}
              {steps[activeStep].id === 3 && (
                <>
                  <KotlerCardHeader
                    minHeight="0px"
                    title="campaignMediaPlanMassMedia"
                    onDelete={() => {
                      _.map( values.mediaAttributes, ( item, index ) => {
                        form.change( `mediaAttributes[${index}].selected`, null );
                        form.change(
                          `mediaAttributes[${index}].intensity`,
                          null,
                        );
                        mediaCostLabel[index] = 0;
                      } );
                    }}
                    downloadTo={
                      this.mkmode.decision.communications.mediaStep
                        ? intl.formatMessage( { id: 'link.download.media.audience' } )
                        : null
                    }
                  />
                  <Grid container style={{ rowGap: '16px' }}>
                    <Grid item xs={12}>
                      {this.mkmode.decision.communications.mediaStep ? (
                        <Grid container style={{ rowGap: '48px' }}>
                          <MassMediaSectionTable
                            name="mediaAttributes"
                            withBigHead
                            filterType="TV"
                            tableValues={valuesTable[0]}
                            values={values}
                            getMediaCost={this.getMediaCost}
                            mediaCostLabel={mediaCostLabel}
                            form={form}
                          />
                          <MassMediaSectionTable
                            name="mediaAttributes"
                            filterType="RADIO"
                            tableValues={valuesTable[1]}
                            values={values}
                            getMediaCost={this.getMediaCost}
                            mediaCostLabel={mediaCostLabel}
                            form={form}
                          />
                          <MassMediaSectionTable
                            name="mediaAttributes"
                            filterType="INTERNET"
                            tableValues={valuesTable[2]}
                            values={values}
                            getMediaCost={this.getMediaCost}
                            mediaCostLabel={mediaCostLabel}
                            form={form}
                          />
                          <MassMediaSectionTable
                            name="mediaAttributes"
                            filterType="OUTDOOR"
                            tableValues={valuesTable[3]}
                            values={values}
                            getMediaCost={this.getMediaCost}
                            mediaCostLabel={mediaCostLabel}
                            form={form}
                          />
                          <Hidden mdUp>
                            <>
                              <Grid item xs={12}>
                                <TableMedioDescription
                                  {...valuesTable[0]}
                                  withBigHead
                                />
                              </Grid>
                              <Grid item xs={12}>
                                <TableMedioDescription {...valuesTable[1]} />
                              </Grid>
                              <Grid item xs={12}>
                                <TableMedioDescription
                                  {...valuesTable[2]}
                                  noHead
                                />
                              </Grid>
                              <Grid item xs={12}>
                                <TableMedioDescription {...valuesTable[3]} />
                              </Grid>
                            </>
                          </Hidden>
                          <Hidden mdDown>
                            <Grid item xs={5} />
                          </Hidden>
                          <Grid item xs={12} lg={7}>
                            <TableMedioDescription
                              noCellOcult
                              {...valuesTable[4]}
                            />
                          </Grid>
                          <Grid item xs={12}>
                            {this.getStepButtons( form )}
                          </Grid>
                        </Grid>
                      ) : (
                        <Row>
                          <Col md={3}>
                            <TextField
                              field="monthlyBudgetInvestment"
                              label="monthlyBudgetInvestment"
                              type="number"
                              validate={required}
                            />
                          </Col>
                        </Row>
                      )}
                    </Grid>
                  </Grid>
                </>
              )}
              {steps[activeStep].id === 4 && (
                <>
                  <Grid container>
                    <Grid item xs={12}>
                      <CardBox
                        styleName="h-100 border-0 "
                        header={
                          <Header title="campaignMediaPlanInternetPlan" />
                        }
                      >
                        <Grid container style={{ rowGap: '16px' }}>
                          <FieldArray name="internetPlans">
                            {( { fields } ) =>
                              fields.map( ( internetPlanField, index ) => {
                                const internetPlan = fields.value[index];
                                if ( !internetPlan ) return null;

                                return (
                                  <Grid
                                    item
                                    sm={6}
                                    md={3}
                                    key={internetPlan.id}
                                  >
                                    <CardBox
                                      styleName="h-100 kotler-card-gray mr-sm-4 mr-md-3 mr-lg-4 d-flex flex-column justify-content-between"
                                      disableHeader
                                      footer={(
                                        <Grid
                                          container
                                          style={{
                                            rowGap: '16px',
                                            marginTop: '16px',
                                          }}
                                        >
                                          <Grid item xs={12}>
                                            <TextField
                                              label="monthlyInvestment"
                                              field={`${internetPlanField}.monthlyInvestment`}
                                              marginContainer={false}
                                              disabled={
                                                internetPlan.id
                                                  !== 'social_network_display'
                                                && !internetPlan.ageFrom
                                                && !internetPlan.ageTo
                                                && ( !internetPlan.gender
                                                  || ( internetPlan.gender
                                                    && internetPlan.gender
                                                      .length === 0 ) )
                                                && ( !internetPlan.body
                                                  || !internetPlan.body.length )
                                              }
                                              type="number"
                                              validate={
                                                ( internetPlan.id
                                                  === 'social_network_display'
                                                  && internetPlan.monthlyInvestment )
                                                || internetPlan.ageFrom
                                                || internetPlan.ageTo
                                                || ( internetPlan.gender
                                                  && internetPlan.gender.length
                                                    !== 0 )
                                                || ( internetPlan.body
                                                  && internetPlan.body.length )
                                                  ? composeValidators(
                                                    greaterOrEqualValue( 0 ),
                                                    lessOrEqualThanValue(
                                                      1000000,
                                                    ),
                                                  )
                                                  : null
                                              }
                                              translateValues={{
                                                value: 0,
                                                value2: 1000000,
                                              }}
                                            />
                                          </Grid>
                                          <Grid item xs={12}>
                                            <TextField
                                              label="estimatedPeopleReached"
                                              field="estimatedPeopleReachedView"
                                              disabled
                                              marginContainer={false}
                                              type="number"
                                              labelClass="kotler-text-field-label"
                                              containerClass="kotler-text-field-form-control"
                                              className="kotler-text-field-gray"
                                              value={
                                                internetPlan.monthlyInvestment
                                                && true
                                                  ? Util.formatNumber(
                                                    // eslint-disable-next-line max-len
                                                    this.calcMonthyInvestment(
                                                      internetPlan.monthlyInvestment,
                                                      internetPlan,
                                                      costIndex,
                                                    ),
                                                  )
                                                  : 0
                                              }
                                            />
                                          </Grid>
                                        </Grid>
                                      )}
                                    >
                                      <Grid
                                        container
                                        style={{ rowGap: '16px' }}
                                      >
                                        <Grid item xs={12}>
                                          <KotlerCardHeader
                                            className="mb-2"
                                            title={internetPlan.label}
                                            onDelete={() => {
                                              form.change(
                                                `${internetPlanField}.monthlyInvestment`,
                                                null,
                                              );
                                              form.change(
                                                `${internetPlanField}.gender`,
                                                null,
                                              );
                                              form.change(
                                                `${internetPlanField}.ageFrom`,
                                                null,
                                              );
                                              form.change(
                                                `${internetPlanField}.ageTo`,
                                                null,
                                              );
                                              form.change(
                                                `${internetPlanField}.body`,
                                                null,
                                              );
                                              form.change(
                                                `${internetPlanField}.body2`,
                                                null,
                                              );
                                              this.setState( { population: -1 } );
                                            }}
                                          />
                                        </Grid>
                                        <Grid item xs={12}>
                                          <Select
                                            label="gender"
                                            field={`${internetPlanField}.gender`}
                                            isMulti
                                            marginContainer={false}
                                            translate
                                            displayBlock
                                            onChange={( value ) => {
                                              if (
                                                internetPlan.id
                                                  !== 'social_network_display'
                                                && !internetPlan.ageTo
                                                && !internetPlan.ageFrom
                                                && ( !value
                                                  || ( value
                                                    && value.length === 0 ) )
                                                && ( !internetPlan.body
                                                  || !internetPlan.body.length )
                                              ) {
                                                form.change(
                                                  `${internetPlanField}.monthlyInvestment`,
                                                  null,
                                                );
                                                form.change(
                                                  `${internetPlanField}.gender`,
                                                  null,
                                                );
                                              }
                                            }}
                                            options={[
                                              {
                                                id: 'MALE',
                                                name: 'male',
                                                value: 'male',
                                              },
                                              {
                                                id: 'FEMALE',
                                                name: 'female',
                                                value: 'female',
                                              },
                                            ]}
                                          />
                                        </Grid>

                                        <Grid item xs={12}>
                                          <Grid
                                            container
                                            wrap="nowrap"
                                            spacing={1}
                                          >
                                            <Grid item>
                                              <TextField
                                                marginContainer={false}
                                                label="age"
                                                field={`${internetPlanField}.ageFrom`}
                                                type="number"
                                                onChange={( value ) => {
                                                  if (
                                                    internetPlan.id
                                                      !== 'social_network_display'
                                                    && !value
                                                    && !internetPlan.ageTo
                                                    && ( !internetPlan.gender
                                                      || ( internetPlan.gender
                                                        && !internetPlan.gender
                                                          .length ) )
                                                    && ( !internetPlan.body
                                                      || !internetPlan.body.length )
                                                  ) {
                                                    form.change(
                                                      `${internetPlanField}.monthlyInvestment`,
                                                      null,
                                                    );
                                                  }
                                                }}
                                                validate={composeValidators(
                                                  greaterThanZeroDependencyNoEmpty(
                                                    `${internetPlanField}.ageTo`,
                                                  ),
                                                )}
                                              />
                                            </Grid>
                                            <Grid
                                              item
                                              className="text-dark"
                                              style={{
                                                marginTop: '33px',
                                                fontWeight: 500,
                                              }}
                                            >
                                              <IntlMessages id="ageto" />
                                            </Grid>
                                            <Grid
                                              item
                                              style={{ marginTop: '23px' }}
                                            >
                                              <TextField
                                                label=""
                                                marginContainer={false}
                                                field={`${internetPlanField}.ageTo`}
                                                type="number"
                                                validate={composeValidators(
                                                  greaterThanZeroDependency(
                                                    `${internetPlanField}.ageFrom`,
                                                  ),
                                                  greaterThan(
                                                    `${internetPlanField}.ageFrom`,
                                                  ),
                                                )}
                                                translateValues={{ label: 'ageFrom' }}
                                                onChange={( value ) => {
                                                  if (
                                                    internetPlan.id
                                                      !== 'social_network_display'
                                                    && !internetPlan.ageFrom
                                                    && !value
                                                    && ( !internetPlan.gender
                                                      || ( internetPlan.gender
                                                        && !internetPlan.gender
                                                          .length ) )
                                                    && ( !internetPlan.body
                                                      || !internetPlan.body.length )
                                                  ) {
                                                    form.change(
                                                      `${internetPlanField}.monthlyInvestment`,
                                                      null,
                                                    );
                                                  }
                                                }}
                                              />
                                            </Grid>
                                          </Grid>
                                        </Grid>

                                        {internetPlan.id
                                          === 'social_network_display' && (
                                          <>
                                            <Grid item xs={12}>
                                              <Checkboxes
                                                adornRequired
                                                label="segmentation"
                                                marginContainer={false}
                                                field={`${internetPlanField}.body`}
                                                labelClassName="col-md-6 mr-0"
                                                labelContainerClass="row"
                                                translate
                                                options={_.filter(
                                                  internetPlan.bodyList,
                                                  { type: 'segmentation' },
                                                )}
                                              />
                                            </Grid>
                                            <Grid item xs={12}>
                                              <Button
                                                variant="outlined"
                                                color="primary"
                                                className="kotler-button-sm py-0 "
                                                type="button"
                                                onClick={() => {
                                                  this.computePopulation(
                                                    values,
                                                  );
                                                }}
                                              >
                                                <IntlMessages id="compute" />
                                              </Button>
                                            </Grid>
                                            {population >= 0 && (
                                              <Grid item xs={12}>
                                                <TextField
                                                  label="populationSample"
                                                  field="populationSampleView"
                                                  disabled
                                                  marginContainer={false}
                                                  type="number"
                                                  labelClass="kotler-text-field-label"
                                                  containerClass="kotler-text-field-form-control"
                                                  className="kotler-text-field-gray"
                                                  value={Util.formatNumber(
                                                    population,
                                                  )}
                                                />
                                              </Grid>
                                            )}
                                          </>
                                        )}

                                        {internetPlan.id
                                          === 'artificial_intelligence' && (
                                          <>
                                            <Grid item xs={12}>
                                              <RadioButtons
                                                label={internetPlan.bodyLabel}
                                                field={`${internetPlanField}.body`}
                                                options={internetPlan.bodyList}
                                                containerClass="w-50"
                                                displayBlock
                                              />
                                              <RadioButtons
                                                disabled={
                                                  !internetPlan.body
                                                  || !internetPlan.body.length
                                                }
                                                field={`${internetPlanField}.body2`}
                                                options={
                                                  internetPlan.bodyListLevel2
                                                }
                                                labelPlacement="end"
                                                displayBlock
                                              />
                                            </Grid>

                                            {/*  <div className="text-right">
                                            <Button
                                              variant="contained"
                                              color="primary"
                                              className="jr-btn jr-btn-lg"
                                              type="button"
                                              onClick={() => {
                                                this.toggleAiModal( );
                                              }}
                                            >
                                              <i className="fa fa-bars" />
                                              <IntlMessages id="detail" />
                                            </Button>
                                          </div> */}
                                          </>
                                        )}

                                        {internetPlan.id
                                          !== 'social_network_display'
                                          && internetPlan.id
                                            !== 'artificial_intelligence' && (
                                          <Grid item xs={12}>
                                            <Checkboxes
                                              label={internetPlan.bodyLabel}
                                              labelClassName="col-md-6 mr-0"
                                              labelContainerClass="row"
                                              marginContainer={false}
                                              translate
                                              field={`${internetPlanField}.body`}
                                              options={
                                                user.activeSession
                                                  .distributionType
                                                  === 'studio'
                                                  ? internetPlan.bodyListStudio
                                                  : internetPlan.bodyList
                                              }
                                              onChange={( value ) => {
                                                if (
                                                  internetPlan.id
                                                      !== 'social_network_display'
                                                    && !internetPlan.ageFrom
                                                    && !internetPlan.ageTo
                                                    && ( !internetPlan.gender
                                                      || ( internetPlan.gender
                                                        && !internetPlan.gender
                                                          .length ) )
                                                    && ( !value || !value.length )
                                                ) {
                                                  form.change(
                                                    `${internetPlanField}.monthlyInvestment`,
                                                    null,
                                                  );
                                                }
                                              }}
                                            />
                                          </Grid>
                                        )}
                                      </Grid>
                                    </CardBox>
                                  </Grid>
                                );
                              } )
                            }
                          </FieldArray>
                        </Grid>
                      </CardBox>
                    </Grid>
                    <Grid item xs={12}>
                      {this.getStepButtons( form )}
                    </Grid>
                  </Grid>
                </>
              )}
              <Grid
                container
                spacing={1}
                justify="space-between"
                style={{ display: steps[activeStep].id === 5 ? 'flex' : 'none' }}
              >
                <Grid item xs={12} sm={8} md={6} lg={5}>
                  <CampaignDetail
                    mkmode={this.mkmode}
                    data={detailData}
                    summary
                  />
                  {steps[activeStep].id === 5 && (
                    <CardBox styleName="px-4 mt-3" disableHeader>
                      <span
                        className="text-dark "
                        style={{ fontSize: '18px', fontWeight: 600 }}
                      >
                        <IntlMessages id="totalMonthlyCost" />
:
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        {Util.formatCurrency(
                          _.sum( mediaCostLabel )
                            + parseFloat(
                              _.get( values, 'monthlyBudgetInvestment', 0.0 ),
                            )
                            + _.sumBy( values.internetPlans, item =>
                              // eslint-disable-next-line no-restricted-globals
                              ( isNaN( item.monthlyInvestment )
                              || !item.monthlyInvestment
                                ? 0.0
                                : parseFloat( item.monthlyInvestment ) ) ),
                        )}
                      </span>
                    </CardBox>
                  )}
                </Grid>
                <Grid item xs={12} sm={8} md={4} lg={4}>
                  <AiGenerateImage
                    {...transformValuesToAiImage( values || {} )}
                  />
                </Grid>
              </Grid>
              {/*
              {' '}
              <Row>
                <Col md={4} className="m-md-auto">
                  <CardBox
                    header={<Header title="finalConfirmation" />}
                    styleName=""
                  >
                    <div className="text-center mt-2">
                      <p>
                        <IntlMessages id="approveCampaign" />
                      </p>
                      <p>
                        <b>
                          <IntlMessages id="totalMonthlyCost" />
:
                        </b>
                        &nbsp;
                        {Util.formatCurrency(
                          _.sum( mediaCostLabel )
                            + parseFloat(
                              _.get( values, 'monthlyBudgetInvestment', 0.0 ),
                            )
                            + _.sumBy( values.internetPlans, item =>
                              // eslint-disable-next-line no-restricted-globals
                              ( isNaN( item.monthlyInvestment )
                              || !item.monthlyInvestment
                                ? 0.0
                                : parseFloat( item.monthlyInvestment ) ) ),
                        )}
                      </p>
                      <Button
                        variant="contained"
                        className="jr-btn jr-btn-lg bg-blue-grey text-white"
                        type="submit"
                        onClick={() => {
                          if ( form.getState().valid ) this.handleBack( form );
                        }}
                      >
                        <i className="fa fa-angle-double-left" />
                        <IntlMessages id="back" />
                      </Button>
                      <Button
                        variant="contained"
                        color="primary"
                        className="jr-btn jr-btn-lg"
                        type="submit"
                      >
                        <i className="fa fa-save" />
                        <IntlMessages id="saveBtn" />
                      </Button>
                    </div>
                  </CardBox>
                </Col>
              </Row>
              {' '}
              */}
            </form>
          )}
        />
        <ConfirmationDialog
          open={confirmExitModal}
          title="confirmExitWithOutSavingButton"
          subTitle="subtitleExitWithoutSaving"
          confirmBtnText="yesModalBtn"
          closeBtnText="cancelBtn"
          onSubmit={() => {
            this.toggleConfirmExitModal();
            this.setConfirmExitModal();
          }}
          onClose={this.toggleConfirmExitModal}
        />
        <AiReportModal
          open={aiModal}
          onClose={this.toggleAiModal}
          title="aiReport"
          data={aditionalData}
        />
      </>
    );
  }
}

const mapStateToProps = ( { user } ) => ( { user } );

const mapDispatchToProps = {
  toggleAlert: SettingsActions.toggleAlert,
  toggleLoading: SettingsActions.toggleLoading,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)( injectIntl( EditCampaign ) );
