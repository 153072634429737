import { useQuery, useQueryClient } from 'react-query';
import apiService from 'services/Cem';
import { showError } from 'utils/alerts';

const get = async ( sessionId, workGroupId, body ) => {
  const res = await apiService.getCemImage( sessionId, workGroupId, body );
  if ( res.ok ) {
    return res.data && res.data.data && res.data.data.imageUrl;
  }
  if ( !res.ok ) {
    if ( res.errors ) {
      throw new Error( res.errors );
    }
    throw new Error( 'Error' );
  }
};

const createRequestBody = ( {
  contentType,
  service,
  serviceFeature,
  serviceFeature2,
  product,
  productFeature,
  productFeature2,
  bundle,
  bundleFeature,
  bundleFeature2,
} ) => {
  let features = {};
  if ( contentType === 'PRODUCT' ) {
    features = { product, productFeature, productFeature2 };
  } else if ( contentType === 'SERVICE' ) {
    features = { service, serviceFeature, serviceFeature2 };
  } else if ( contentType === 'BUNDLE' ) {
    features = { bundle, bundleFeature, bundleFeature2 };
  }

  const body = {
    contentType,
    ...features,
  };

  return body;
};
const getEnable = (
  sessionId,
  workgroupId,
  { service, product, bundle, contentType } = {},
) => {
  if ( !sessionId || !workgroupId || !contentType ) return false;
  if (
    ( contentType === 'PRODUCT' && !product )
    || ( contentType === 'SERVICE' && !service )
    || ( contentType === 'BUNDLE' && !bundle )
  ) return false;

  return true;
};

const createQueryKey = ( sessionId, workGroupId, features = {} ) => [
  'v2',
  sessionId,
  workGroupId,
  'campaign',
  'new',
  'image',
  ...Object.values( features ).filter( value => !!value ),
];

// queries hooks
export const useCapaignImageAiQuery = ( sessionId, workGroupId, params = {} ) => {
  const body = createRequestBody( params );
  const features = body;

  const imageQuery = useQuery(
    createQueryKey( sessionId, workGroupId, features ),
    () => get( sessionId, workGroupId, body ),
    {
      enabled: getEnable( sessionId, workGroupId, params ),
      staleTime: 1000 * 60 * 60 * 12,
      retryOnMount: true,
      retry: 0,
      onError( error ) {
        showError( error && error.message );
      },
    },
  );

  return { imageQuery };
};

export const usePrefetchCapaignImageAiQuery = (
  sessionId,
  workGroupId,
  params = {},
) => {
  const body = createRequestBody( params );
  const features = body;
  const queryClient = useQueryClient();

  const prefetchAiImage = () => {
    queryClient.prefetchQuery(
      createQueryKey( sessionId, workGroupId, features ),
      () => get( sessionId, workGroupId, body ),
      {
        enabled: getEnable( sessionId, workGroupId, params ),
        staleTime: 1000 * 60 * 60 * 12,
        retryOnMount: true,
        retry: 0,
      },
    );
  };

  return { prefetchAiImage };
};
