import React from 'react';
import { Grid } from '@material-ui/core';
import FeatherIcon from 'components/custom/FeatherIcon';

import KotlerTabs from 'components/custom/KotlerTabs';

import PieGraphLastRoundBundles from 'modules/Bundles/components/PieGraphLastRoundBundles';
import PieGraphLastRoundServices from 'modules/Services/components/PieGraphLastRoundServices';
import TableLastRoundServices from 'modules/Services/components/TableLastRoundServices';

import LineGraphNumberOfCustomers from 'modules/Customers/components/LineGraphNumberOfCustomers';
import PieGraphNumberOfCustomers from 'modules/Customers/components/PieGraphNumberOfCustomers';
import LineGraphMarketProduct from 'modules/Products/components/LineGraphMarketProduct';
import LineGraphPriceProduct from 'modules/Products/components/LineGraphPriceProduct';
import RadarGraphInfoProduct from 'modules/Products/components/RadarGraphInfoProduct';
import TableInfoProduct from 'modules/Products/components/TableInfoProduct';
import PieGraphMarketProduct from 'modules/Products/components/PieGraphMarketProduct';
import TableMarketProduct from 'modules/Products/components/TableMarketProduct';
import TablesLastRoundProduct from 'modules/Products/components/TablesLastRoundProduct';
import TableLastRoundBundles from 'modules/Bundles/components/TableLastRoundBundles';
import TableLastRoundServicesPrice from 'modules/Services/components/TableLastRoundServicesPrice';
import LineGraphMarketStimate from 'modules/Market/components/LineGraphMarketStimate';
import TableMarketStimate from 'modules/Market/components/TableMarketStimate';
import MarketReportBtn from '../components/MarketReportBtn';

const MarketContainer = ( { mkmode, categories } ) => (
  <KotlerTabs
    renderOneView
    endHeadComponent={(
      <MarketReportBtn
        categories={categories}
        mkmode={mkmode}
        isStudio={!mkmode.information.market.laptop}
      />
    )}
    titles={[
      {
        title: 'customers',
        icon: <FeatherIcon size="20px" icon="users" />,
      },
      ...( mkmode.information.market.laptop
        ? [
          {
            title: 'laptops',
            icon: <FeatherIcon size="20px" icon="monitor" />,
          },
        ]
        : [] ),
      ...( mkmode.information.market.tablet
        ? [
          {
            title: 'tablets',
            icon: <FeatherIcon size="20px" icon="tablet" />,
          },
        ]
        : [] ),

      {
        title: 'cellPhones',
        icon: <FeatherIcon size="20px" color="primary.main" icon="smartphone" />,
      },
      ...( mkmode.information.market.bundle
        ? [
          {
            title: 'services and bundles',
            icon: <FeatherIcon size="20px" icon="layers" />,
          },
        ]
        : [] ),
      {
        title: 'market stimate',
        icon: <FeatherIcon size="20px" icon="arrowUpRight" />,
      },
    ]}
  >
    {/* // section customers */}
    <Grid container style={{ rowGap: '20px' }}>
      <Grid item xs={12} md={6} style={{ minHeight: '400px' }}>
        <LineGraphNumberOfCustomers gId={1} />
      </Grid>
      <Grid item xs={12} md={6} style={{ minHeight: '400px' }}>
        <PieGraphNumberOfCustomers gId={1} />
      </Grid>
    </Grid>
    {!!mkmode.information.market.laptop && !!categories && (
      <Grid container>
        <Grid item xs={12} md={6}>
          <div className="d-flex h-100 flex-column justify-content-between flex-grow-1">
            <LineGraphMarketProduct
              title="LAPTOPSalesByBrandU"
              titleValue="SalesU"
              gId="laptopsalesbybrandu"
              graphHeight="250px"
              categoryId={categories && categories[0] && categories[0].id}
              dataKey="unitSales"
            />
            <LineGraphMarketProduct
              title="LAPTOPSalesByBrandRevenue"
              titleValue="SalesS"
              gId="laptopsalesbybrandr"
              graphHeight="250px"
              categoryId={categories && categories[0] && categories[0].id}
              dataKey="revenues"
            />
            <LineGraphPriceProduct
              categoryId={categories && categories[0] && categories[0].id}
              graphHeight="250px"
              title="analytics.LaptopPriceByProduct"
              gId="laptopPricebyproduct"
            />
            <RadarGraphInfoProduct
              title="laptops"
              gId="laptopAttributes"
              graphHeight="350px"
              categoryId={categories && categories[0] && categories[0].id}
            >
              <TableInfoProduct
                categoryId={categories && categories[0] && categories[0].id}
              />
            </RadarGraphInfoProduct>
          </div>
        </Grid>
        <Grid item xs={12} md={6}>
          <PieGraphMarketProduct
            title="LAPTOPMARKET"
            gId="laptopmarket"
            graphHeight="315px"
            categoryId={categories && categories[0] && categories[0].id}
            dataKey="unitSales"
          >
            <TableMarketProduct
              categoryId={categories && categories[0] && categories[0].id}
            />

            <div className="d-flex flex-grow-1 flex-column justify-content-between">
              <TablesLastRoundProduct
                categoryId={categories && categories[0] && categories[0].id}
              />
            </div>
          </PieGraphMarketProduct>
        </Grid>
      </Grid>
    )}
    {/* // section tablet */}
    {!!mkmode.information.market.tablet && !!categories && (
      <Grid container>
        <Grid item xs={12} md={6}>
          <div className="d-flex  h-100 flex-column justify-content-between flex-grow-1">
            <LineGraphMarketProduct
              title="TABLETSalesByBrandU"
              titleValue="SalesU"
              gId="tabletsalesbybrandu"
              graphHeight="250px"
              categoryId={categories && categories[1] && categories[1].id}
              dataKey="unitSales"
            />
            <LineGraphMarketProduct
              title="TABLETSalesByBrandRevenue"
              titleValue="SalesS"
              gId="tabletsalesbybrandr"
              graphHeight="250px"
              categoryId={categories && categories[1] && categories[1].id}
              dataKey="revenues"
            />
            <LineGraphPriceProduct
              categoryId={categories && categories[1] && categories[1].id}
              graphHeight="250px"
              title="analytics.TabletPriceByProduct"
              gId="tabletPricebyproduct"
            />

            <RadarGraphInfoProduct
              title="tablets"
              gId="tabletAttributes"
              graphHeight="350px"
              categoryId={categories && categories[1] && categories[1].id}
            >
              <TableInfoProduct
                categoryId={categories && categories[1] && categories[1].id}
              />
            </RadarGraphInfoProduct>
          </div>
        </Grid>
        <Grid item xs={12} md={6}>
          <PieGraphMarketProduct
            title="TABLETMARKET"
            gId="tabletmarket"
            graphHeight="315px"
            categoryId={categories && categories[1] && categories[1].id}
            dataKey="unitSales"
          >
            <div className="d-flex flex-grow-1 flex-column justify-content-between">
              <TableMarketProduct
                categoryId={categories && categories[1] && categories[1].id}
              />

              <TablesLastRoundProduct
                categoryId={categories && categories[1] && categories[1].id}
              />
            </div>
          </PieGraphMarketProduct>
        </Grid>
      </Grid>
    )}
    <Grid container>
      <Grid item xs={12} md={6}>
        <div className="d-flex  h-100 flex-column justify-content-between flex-grow-1">
          <LineGraphMarketProduct
            title="CELLPHONESalesByBrandU"
            gId="cellphonesalesbybrandu"
            categoryId={categories && categories[2] && categories[2].id}
            titleValue="SalesU"
            graphHeight="250px"
            dataKey="unitSales"
          />
          <LineGraphMarketProduct
            title="CELLPHONESalesByBrandRevenue"
            gId="cellphonesalesbybrandr"
            categoryId={categories && categories[2] && categories[2].id}
            titleValue="SalesS"
            graphHeight="250px"
            dataKey="revenues"
          />
          <LineGraphPriceProduct
            categoryId={categories && categories[2] && categories[2].id}
            graphHeight="250px"
            title="analytics.CellPhonePriceByProduct"
            gId="cellphonePricebyproduct"
          />
          <RadarGraphInfoProduct
            title="cellulars"
            gId="cellphoneAttributes"
            graphHeight="350px"
            categoryId={categories && categories[2] && categories[2].id}
          >
            <TableInfoProduct
              categoryId={categories && categories[2] && categories[2].id}
            />
          </RadarGraphInfoProduct>
        </div>
      </Grid>
      <Grid item xs={12} md={6}>
        <PieGraphMarketProduct
          title="CELLPHONEMARKET"
          gId="cellphonemarket"
          graphHeight="315px"
          categoryId={categories && categories[2] && categories[2].id}
          dataKey="unitSales"
        >
          <div className="d-flex flex-grow-1 flex-column justify-content-between">
            <TableMarketProduct
              categoryId={categories && categories[2] && categories[2].id}
            />

            <TablesLastRoundProduct
              categoryId={categories && categories[2] && categories[2].id}
            />
          </div>
        </PieGraphMarketProduct>
      </Grid>
    </Grid>
    {/* // section services and bundles */}
    {!!mkmode.information.market.bundle && (
      <Grid container className="mb-3" style={{ rowGap: '20px' }}>
        <Grid item xs={12}>
          <TableLastRoundServicesPrice />
        </Grid>
        <Grid item xs={12} md={6}>
          <TableLastRoundServices />
        </Grid>
        <Grid item xs={12} md={6}>
          <PieGraphLastRoundServices graphHeight="315px" />
        </Grid>

        <Grid item xs={12} md={6}>
          <TableLastRoundBundles />
        </Grid>
        <Grid item xs={12} md={6}>
          <PieGraphLastRoundBundles graphHeight="315px" />
        </Grid>
      </Grid>
    )}
    <Grid container className="mb-3" style={{ rowGap: 60 }}>
      {mkmode.information.market.laptop && (
        <Grid item xs={12}>
          <LineGraphMarketStimate
            attribute="LAPTOPS"
            title="laptop.market stimate next 12 months"
          >
            <TableMarketStimate attribute="LAPTOPS" disableDecoration />
          </LineGraphMarketStimate>
        </Grid>
      )}
      {mkmode.information.market.tablet && (
        <Grid item xs={12}>
          <LineGraphMarketStimate
            attribute="TABLET"
            title="tablet.market stimate next 12 months"
          >
            <TableMarketStimate attribute="TABLET" disableDecoration />
          </LineGraphMarketStimate>
        </Grid>
      )}
      <Grid item xs={12}>
        <LineGraphMarketStimate
          attribute="CELL PHONES"
          title="cellPhone.market stimate next 12 months"
        >
          <TableMarketStimate attribute="CELL PHONES" disableDecoration />
        </LineGraphMarketStimate>
      </Grid>
      {mkmode.information.market.bundle && (
        <>
          <Grid item xs={12}>
            <LineGraphMarketStimate
              attribute="SERIES & MOVIES"
              title="series&movies.market stimate next 12 months"
            >
              <TableMarketStimate
                attribute="SERIES & MOVIES"
                disableDecoration
              />
            </LineGraphMarketStimate>
          </Grid>
          <Grid item xs={12}>
            <LineGraphMarketStimate
              attribute="NEWS"
              title="news.market stimate next 12 months"
            >
              <TableMarketStimate attribute="NEWS" disableDecoration />
            </LineGraphMarketStimate>
          </Grid>
          <Grid item xs={12}>
            <LineGraphMarketStimate
              attribute="MUSIC"
              title="music.market stimate next 12 months"
            >
              <TableMarketStimate attribute="MUSIC" disableDecoration />
            </LineGraphMarketStimate>
          </Grid>
        </>
      )}
    </Grid>
  </KotlerTabs>
);

export default MarketContainer;
